import React, { useEffect, useState, useContext } from "react"
import { IonText, IonRow, IonCol, IonList, IonIcon, IonSpinner, IonItem, IonSelect, IonSelectOption, IonLabel } from "@ionic/react"
import { AccordionDetails, Accordion, AccordionSummary, Divider } from "@material-ui/core"
import { chevronDownOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import { DatabaseContext } from "./DatabaseContext"
import { Bar } from "react-chartjs-2"
import axios from "axios"
import _ from "lodash"

const PlaceMonitoring = (props) => {
	const { t } = useTranslation("link_app")
	const place = props.doc

	// Databases
	const databases = useContext(DatabaseContext)

	// API call
	const [isLoading, setLoading] = useState(false)
	const [isError, setError] = useState(false)
	const [monitoringData, setMonitoringData] = useState({})
	const [frequencyDoesntMatch, setFrequencyDoesntMatch] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			setError(false)
			setLoading(true)
			try {
				const response = await axios(window.location.host.includes("localhost") ? `https://api.linkapp-staging.taiste.fi/site/${place.site_id}` : `https://${window.location.host.replace("app", "api")}/site/${place.site_id}`)
				setMonitoringData(response.data)
			} catch (err) {
				setError(true)
				console.log(err)
			}
			setLoading(false)
		}

		fetchData()
	}, [place])

	// Fetch fm services
	const [fmServices, setFmServices] = useState([])
	useEffect(() => {
		if (place && place.fm_services && place.fm_services.length > 0) {
			databases.locals.fm_services
				.allDocs({ include_docs: true, keys: place.fm_services })
				.then((result) => {
					setFmServices(result.rows)
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [place, databases.locals.fm_services])

	// Selected service management
	const [selectedFmService, setSelectedFmService] = useState(0)
	const [selectedFmServiceFromAPI, setSelectedFmServiceFromAPI] = useState({})
	useEffect(() => {
		setFrequencyDoesntMatch(false)
		if (selectedFmService !== 0) {
			if (!_.isEmpty({ ...monitoringData })) {
				const foundService = monitoringData.find((fmService) => fmService[0].title === selectedFmService)
				if (foundService !== undefined) {
					setSelectedFmServiceFromAPI(foundService[0])
				} else {
					setFrequencyDoesntMatch(true)
				}
				//console.log(selectedFmServiceFromAPI)
			}
		} else if (fmServices.length > 0) {
			if (fmServices[0]?.doc?.frequency !== 0) {
				setSelectedFmService(parseInt(fmServices[0]?.doc?.frequency))
			}
		}
	}, [selectedFmService, fmServices, monitoringData, selectedFmServiceFromAPI])

	// Chart.js
	const options = {
		responsive: true,

		plugins: {
			legend: {
				position: "bottom",
				display: false,
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
					drawBorder: false,
					drawOnChartArea: false,
					drawTicks: false,
				},
			},
			y: {
				display: false,
				grid: {
					display: false,
					drawBorder: false,
					drawOnChartArea: false,
					drawTicks: false,
				},
			},
		},
	}

	return (
		<IonList>
			<div className="ion-padding-top ion-padding-bottom">
				<IonItem lines="none">
					<IonText slot="start">
						<h5 className="ion-margin-none">{t("places.fm_service")}</h5>
					</IonText>
					<IonLabel style={{ display: "none" }}>{t("places.fm_service")}</IonLabel>
					<IonSelect slot="end" style={{ maxWidth: "unset" }} value={selectedFmService} okText={t("app.select_ok")} cancelText={t("app.select_cancel")} onIonChange={(e) => setSelectedFmService(parseInt(e.target.value))}>
						{fmServices?.length > 0 &&
							fmServices.map((fmService, i) => {
								return (
									<IonSelectOption key={i} value={parseInt(fmService.doc?.frequency)}>
										{fmService.doc?.name !== ""
											? fmService.doc?.name + ", " + fmService.doc?.frequency.substring(0, fmService.doc?.frequency.length - 2) + "." + fmService.doc?.frequency.substring(fmService.doc?.frequency.length - 2)
											: fmService.doc?.frequency.substring(0, fmService.doc?.frequency.length - 2) + "." + fmService.doc?.frequency.substring(fmService.doc?.frequency.length - 2)}
									</IonSelectOption>
								)
							})}
						}
					</IonSelect>
				</IonItem>
			</div>
			<Divider />
			{selectedFmService !== 0 &&
				!frequencyDoesntMatch &&
				selectedFmServiceFromAPI?.data?.length > 0 &&
				selectedFmServiceFromAPI.data.map((accordion, i) => {
					return (
						<IonItem key={i} lines="full">
							<div className="inner-wrap">
								<Accordion>
									<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
										<IonText>
											<h5 className="ion-margin-none">{accordion.title + ", (" + accordion?.type + ")"}</h5>
										</IonText>
									</AccordionSummary>
									<AccordionDetails>
										{accordion?.data?.length > 0 &&
											accordion.data.map((tab, i) => {
												return (
													<IonRow key={"T" + i} style={{ marginBottom: "12px" }}>
														<IonCol size="12">
															<IonRow className="monitoring-section">
																<IonCol size="12" style={{ marginTop: "16px", marginLeft: "10px" }}>
																	<p>{tab.title}</p>
																</IonCol>
															</IonRow>
															{/*Text values are listed first*/}
															{tab?.data?.data?.length > 0 &&
																tab?.data?.data?.map((value, i) => {
																	if (value.type === "text") {
																		return (
																			<IonRow key={"V" + i} style={{ marginBottom: "24px", marginTop: "24px" }}>
																				<IonCol size="6">
																					<IonText color="medium" className="ion-margin-left">
																						{value.name}
																					</IonText>
																				</IonCol>

																				<IonCol size="6" style={{ textAlign: "end" }}>
																					{/*  Value */}
																					{value.name === "ipAddress" ? (
																						<a className=" ion-margin-right" href={"http://" + value.value + "/"} rel="noreferrer" target="_blank">
																							{value.value}
																						</a>
																					) : (
																						<IonText className=" ion-margin-right">{value.value}</IonText>
																					)}
																				</IonCol>
																			</IonRow>
																		)
																	} else {
																		return null
																	}
																})}
															{/*Then the graphs*/}
															<IonRow style={{ marginBottom: "12px" }}>
																{tab?.data?.data?.length > 0 &&
																	tab?.data?.data?.map((value, i) => {
																		if (value.type === "graph") {
																			return (
																				<IonCol key={"V" + i} size="3" style={{ marginTop: "10px", marginLeft: "10px", textAlign: "center" }}>
																					<Bar
																						data={{
																							labels: [
																								value.reference?.low ? value.reference?.low + value.transmitter_unit : " ",
																								value.value + value.transmitter_unit,
																								value.reference?.high ? value.reference?.high + value.transmitter_unit : " ",
																							],
																							datasets: [
																								{
																									data: [value.reference?.low, value.value, value.reference?.high],
																									backgroundColor: ["rgba(215, 216, 218, 0.35)", "rgba(231, 29, 56, 0.35)", "rgba(215, 216, 218, 0.35)"],
																								},
																							],
																						}}
																						options={options}
																						width={50}
																						height={70}
																					/>
																					<Divider className="ion-margin-top-half ion-margin-bottom-half" />
																					<div>
																						<IonText>{value.name}</IonText>
																					</div>
																				</IonCol>
																			)
																		} else {
																			return null
																		}
																	})}
															</IonRow>
														</IonCol>
													</IonRow>
												)
											})}
									</AccordionDetails>
								</Accordion>
							</div>
						</IonItem>
					)
				})}
			{frequencyDoesntMatch && (
				<IonRow style={{ marginBottom: "32px", marginTop: "32px" }} className="ion-justify-content-center">
					<IonCol size="10" className="ion-text-center">
						<IonText color="danger">{t("app.frequency_doesnt_match")}</IonText>
					</IonCol>
				</IonRow>
			)}
			{isLoading && (
				<IonRow style={{ marginBottom: "32px", marginTop: "32px" }} className="ion-justify-content-center">
					<IonCol size="12" className="ion-text-center">
						<IonSpinner name="crescent" color="primary" />
					</IonCol>
				</IonRow>
			)}
			{isError && (
				<IonRow style={{ marginBottom: "32px", marginTop: "32px" }} className="ion-justify-content-center">
					<IonCol size="12" className="ion-text-center">
						<IonText color="danger">{t("app.fetch_error")}</IonText>
					</IonCol>
				</IonRow>
			)}
		</IonList>
	)
}
export default PlaceMonitoring
