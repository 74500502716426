import React, { useEffect, useState } from "react"
import { IonButton, IonItem, IonLabel, IonIcon, IonCard, IonCardContent } from "@ionic/react"
import { cube, callOutline, navigateOutline, chevronForwardOutline } from "ionicons/icons"
import ReactMapGL, { Marker } from "react-map-gl"
import { ReactComponent as MarkerIcon } from "../assets/marker.svg"
import { useTranslation } from "react-i18next"
import convert from "../convert"
import useWindowSize from "../hooks/useWindowSize"

//Workaround for map not showing in production build. See more at https://github.com/mapbox/mapbox-gl-js/issues/10173 and https://github.com/visgl/react-map-gl/pull/1365
import mapboxgl from "mapbox-gl"
//eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const WarehouseCard = (props) => {
	const { t } = useTranslation("link_app")
	const [width] = useWindowSize()

	const [viewport, setViewport] = useState({
		longitude: null,
		latitude: null,
		zoom: 10,
	})
	const MAPBOX_TOKEN = "pk.eyJ1Ijoib2xsaWthcmtrYWluZW4iLCJhIjoiY2tpN2NjMGxwMzNjZjMwbHR4Y2tjbWtrcyJ9.pXEKiASHCH_D4hzCaquTyw"

	useEffect(() => {
		if (props.warehouse.coordinates !== undefined && viewport.longitude === null && viewport.latitude === null) {
			setViewport({
				...viewport,
				longitude: convert(props.warehouse.coordinates[0]),
				latitude: convert(props.warehouse.coordinates[1]),
			})
		}
	}, [props.warehouse.coordinates, viewport])

	return (
		<IonCard className="ion-border">
			<IonItem lines="none" className="ion-margin-top-half ion-margin-bottom-half" routerLink={`/places/warehouse/${props.warehouse?._id}`}>
				<div className="icon-wrap medium" slot="start">
					<IonIcon icon={cube} style={{ fontSize: "24px" }} color="light" />
				</div>
				<IonLabel style={{ lineHeight: "24px" }}>
					{props.warehouse.name}
					<br />
					<span style={{ opacity: "0.5" }}>
						{props.warehouse.address}
						{`, ${props.warehouse.postal_address}`}
					</span>
				</IonLabel>
				<IonIcon slot="end" icon={chevronForwardOutline} style={{ fontSize: "24px" }} color="medium" />
			</IonItem>
			<IonCardContent className="ion-padding-none">
				{viewport.longitude && viewport.latitude && (
					<ReactMapGL className="place-card-map" {...viewport} onViewportChange={setViewport} width={`${width - 32}px`} height="250px" mapboxApiAccessToken={MAPBOX_TOKEN} mapStyle="mapbox://styles/mapbox/outdoors-v11">
						<Marker longitude={convert(props.warehouse.coordinates[0])} latitude={convert(props.warehouse.coordinates[1])} offsetLeft={-24} offsetTop={-60}>
							<div className="marker-inner-wrap">
								<MarkerIcon className="map-marker medium" />
								<div className="marker-status">
									<IonIcon className="marker-icon" color="light" icon={cube} />
								</div>
							</div>
						</Marker>
					</ReactMapGL>
				)}
				<IonItem lines="none">
					<div className="inner-wrap ion-no-padding ion-padding-top">
						<IonLabel style={{ marginBottom: "5px" }}>{t("tickets.warehouse_contact_personel")}</IonLabel>
						{props.warehouse.contact_personel && props.warehouse.contact_personel.person_1.name && (
							<IonItem href={`tel:${props.warehouse.contact_personel.person_1.phone_number}`} lines="none" className="ion-padding-top-half ion-padding-bottom-half">
								<div className="icon-wrap primary" slot="start">
									{props.warehouse.contact_personel.person_1.name !== "" && props.warehouse.contact_personel.person_1.name.split(" ").length >= 1 ? (
										<>
											{props.warehouse.contact_personel.person_1.name.split(" ").length >= 1 && props.warehouse.contact_personel.person_1.name.split(" ")[0].charAt(0)}
											{props.warehouse.contact_personel.person_1.name.split(" ").length > 1 && props.warehouse.contact_personel.person_1.name.split(" ")[1].charAt(0)}
										</>
									) : (
										"?"
									)}
								</div>
								<IonLabel>
									{props.warehouse.contact_personel.person_1.name}
									<br />
									<span className="contact-title">{props.warehouse.contact_personel.person_1.phone_number}</span>
								</IonLabel>
								<IonIcon icon={callOutline} color="primary" slot="end" />
							</IonItem>
						)}
						{props.warehouse.contact_personel && props.warehouse.contact_personel.person_2.name && (
							<IonItem href={`tel:${props.warehouse.contact_personel.person_2.phone_number}`} lines="none" className="ion-padding-top-half ion-padding-bottom-half">
								<div className="icon-wrap primary" slot="start">
									{props.warehouse.contact_personel.person_2.name !== "" && props.warehouse.contact_personel.person_2.name.split(" ").length >= 1 ? (
										<>
											{props.warehouse.contact_personel.person_2.name.split(" ").length >= 1 && props.warehouse.contact_personel.person_2.name.split(" ")[0].charAt(0)}
											{props.warehouse.contact_personel.person_2.name.split(" ").length > 1 && props.warehouse.contact_personel.person_2.name.split(" ")[1].charAt(0)}
										</>
									) : (
										"?"
									)}
								</div>
								<IonLabel>
									{props.warehouse.contact_personel.person_2.name}
									<br />
									<span className="contact-title">{props.warehouse.contact_personel.person_2.phone_number}</span>
								</IonLabel>
								<IonIcon icon={callOutline} color="primary" slot="end" />
							</IonItem>
						)}
					</div>
				</IonItem>
				{props.warehouse.coordinates.length > 0 && (
					<IonButton className="ion-margin-left-half ion-margin-top-half ion-margin-bottom-half" href={`https://www.google.com/maps/search/?api=1&query=${convert(props.warehouse.coordinates[1])},${convert(props.warehouse.coordinates[0])}`} fill="clear" target="_blank">
						<IonIcon className="marker-icon" slot="start" icon={navigateOutline} color="primary" />
						{t("places.navigate")}
					</IonButton>
				)}
			</IonCardContent>
		</IonCard>
	)
}
export default WarehouseCard
