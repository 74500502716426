import React from "react"
import { IonItem, IonText, IonIcon, IonCol } from "@ionic/react"
import { chevronDownOutline } from "ionicons/icons"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import { useTranslation } from "react-i18next"

const StudioTransmitters = (props) => {
	const place = props.doc
	const { t } = useTranslation("link_app")
	console.log(place)
	return (
		<div className="ion-padding-none">
			{place?.transmitters?.map((transmitter, i) => {
				return (
					<IonItem lines="full">
						<div className="inner-wrap">
							<Accordion>
								<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
									<IonText>
										<h5 className="ion-margin-none">{transmitter.name}</h5>
									</IonText>
								</AccordionSummary>
								<AccordionDetails>
									<IonItem lines="none">
										<IonCol size="6" className="ion-padding-right-half ion-margin-none">
											<IonText color="medium">{t("places.ip_address")}</IonText>
										</IonCol>
										<IonCol size="6" className="ion-padding-left-half ion-margin-none">
											<IonText>
												<a href={`http://${transmitter.ip_address}`} rel="noreferrer" target="_blank">
													{transmitter.ip_address}
												</a>
											</IonText>
										</IonCol>
									</IonItem>
									{transmitter.masts.map((mast, i) => {
										return (
											<IonItem lines="none">
												<IonCol size="6" className="ion-padding-right-half ion-margin-none">
													<IonText color="medium">{mast.name}</IonText>
												</IonCol>
												<IonCol size="6" className="ion-padding-left-half ion-margin-none">
													<IonText>
														<a href={`http://${mast.ip_address}`} rel="noreferrer" target="_blank">
															{mast.ip_address}
														</a>
													</IonText>
												</IonCol>
											</IonItem>
										)
									})}
								</AccordionDetails>
							</Accordion>
						</div>
					</IonItem>
				)
			})}
			}
		</div>
	)
}

export default StudioTransmitters
