import React, { useState } from "react"
import { IonText, IonRow, IonCol, IonGrid, IonIcon, IonButton, IonItem } from "@ionic/react"
import { Accordion, AccordionSummary, AccordionDetails, Divider } from "@material-ui/core"
import { chevronDownOutline, chevronBack, chevronForwardOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import PictureList from "./PictureList"
import ValueList from "./ValueList"

const PlacesFMServices = (props) => {
	const { t } = useTranslation("link_app")
	const place = props.doc

	// Which service is selected from the list
	const [serviceSelected, setServiceSelected] = useState()

	console.log(place)

	return (
		<IonGrid className="ion-padding-none">
			{!serviceSelected &&
				props.fmServices?.map((fmService, i) => {
					return (
						<React.Fragment key={i}>
							<IonRow style={{ cursor: "pointer" }} className="ion-padding" onClick={() => setServiceSelected(fmService.doc)}>
								<IonCol size="10" className="ion-margin-none">
									<IonText>
										<h5>{fmService?.doc?.name}</h5>
									</IonText>
									<IonText color="medium">
										<p className="ion-margin-none">{fmService?.doc?.frequency?.substring(0, fmService?.doc?.frequency?.length - 2) + "." + fmService?.doc?.frequency?.substring(fmService?.doc?.frequency?.length - 2)}</p>
									</IonText>
								</IonCol>
								<IonCol size="2" class="ion-text-right ion-margin-none" style={{ alignSelf: "center" }}>
									<IonIcon color="medium" style={{ fontSize: "24px" }} icon={chevronForwardOutline}></IonIcon>
								</IonCol>
							</IonRow>
							<Divider />
						</React.Fragment>
					)
				})}
			<div className={serviceSelected ? "fm-service-container is-active" : "fm-service-container"}>
				<IonRow className="ion-padding">
					<IonCol size="12">
						<IonButton className="ion-margin-bottom" style={{ marginLeft: "-16px" }} size="default" fill="clear" color="medium" onClick={() => setServiceSelected()}>
							<IonIcon slot="start" icon={chevronBack} /> {t("app.back")}
						</IonButton>
						<IonRow className="ion-margin-bottom">
							<IonCol size="6" className="ion-padding-right-half ion-margin-none">
								<IonText color="medium">{t("places.name")}</IonText>
							</IonCol>
							<IonCol size="6" className="ion-padding-left-half ion-margin-none">
								<IonText>{serviceSelected?.name}</IonText>
							</IonCol>
						</IonRow>
						<IonRow className="ion-margin-bottom">
							<IonCol size="6" className="ion-padding-right-half ion-margin-none">
								<IonText color="medium">{t("places.frequency")}</IonText>
							</IonCol>
							<IonCol size="6" className="ion-padding-left-half ion-margin-none">
								<IonText>{serviceSelected?.frequency}</IonText>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="6" className="ion-padding-right-half ion-margin-none">
								<IonText color="medium">{t("places.service_number")}</IonText>
							</IonCol>
							<IonCol size="6" className="ion-padding-left-half ion-margin-none">
								<IonText>{serviceSelected?.number}</IonText>
							</IonCol>
						</IonRow>
					</IonCol>
				</IonRow>
				<Divider />
				<IonRow className="ion-margin-bottom">
					<IonCol size="12">
						{serviceSelected &&
							Object.keys(serviceSelected?.tabs).map((key, i) => {
								return (
									<IonItem key={i} lines="full">
										<div className="inner-wrap">
											<Accordion>
												<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
													<IonText>
														<h5 className="ion-margin-none">{t(`places.${key}`)}</h5>
													</IonText>
												</AccordionSummary>
												<AccordionDetails>
													<PictureList tabName="tabs" fmServiceId={serviceSelected._id} listName={key} place={serviceSelected} />
													<ValueList tabName="tabs" listName={key} place={serviceSelected} />
												</AccordionDetails>
											</Accordion>
										</div>
									</IonItem>
								)
							})}
					</IonCol>
				</IonRow>
			</div>
		</IonGrid>
	)
}
export default PlacesFMServices
