import React, { useState, useContext, useEffect } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonList, IonText, IonButton, IonItem, IonLabel, IonIcon, IonActionSheet, IonBadge } from "@ionic/react"
import { listOutline, chevronDownOutline, alertCircleOutline, radioOutline, timeOutline, readerOutline, chevronForwardOutline, hammerOutline, locationOutline, cartOutline, pinOutline, navigateOutline, closeOutline, checkmarkOutline } from "ionicons/icons"
import Countdown from "react-countdown"
import DateTime from "../components/DateTime"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"
import useToast from "../hooks/useToast"
import SlaDiff from "../components/SlaDiff"
import { UserContext } from "../components/UserContext"
import _ from "lodash"

const Ticket = (props) => {
	// Misc vars
	const { t } = useTranslation("link_app")
	const match = props.match
	const [user] = useContext(UserContext)
	const toast = useToast()

	// State for action sheet
	const [showActionSheet, setShowActionSheet] = useState(false)

	// Databases
	const databases = useContext(DatabaseContext)

	// Edited state
	const [isEdited, setIsEdited] = useState(false)

	// Updated state
	const [isUpdated, setIsUpdated] = useState(true)

	// Fetch the ticket
	const [doc, setDoc] = useState({})
	useEffect(() => {
		databases.locals.tickets
			.get(match.params.id)
			.then((result) => {
				setDoc(result)
				setIsUpdated(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.tickets, match, props.isLoading, isUpdated])

	useEffect(() => {
		document.title = `${t("tickets.ticket")} #${doc._id} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [t, doc, match])

	// Fetch all places
	const [places, setPlaces] = useState([])
	useEffect(() => {
		databases.locals.places
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				setPlaces(docs)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.places])

	// Fetch all studios
	const [studios, setStudios] = useState([])
	useEffect(() => {
		databases.locals.studios
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				setStudios(docs)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.studios])

	// Get place
	const [place, setPlace] = useState({})
	useEffect(() => {
		if (!_.isEmpty(doc.place) && places?.length > 0) {
			const site = places.find((place) => place.doc.site_id === doc?.place?.site_id)
			if (site) {
				setPlace(site.doc)
			}
			const studio = studios.find((place) => place.doc.site_id === doc?.place?.site_id)
			if (studio) {
				setPlace(studio.doc)
			}
		}
	}, [doc.place, places, studios])

	// Update changes to database
	useEffect(() => {
		if (isEdited) {
			databases.locals.tickets
				.put(doc)
				.then((result) => {
					console.log(result)
					console.log(`${t("toast.ticket")} #${doc._id} ${t("toast.updated")}`)
					toast(`${t("toast.ticket")} #${doc._id} ${t("toast.updated")}`)
					setIsEdited(false)
					setIsUpdated(true)
				})
				.catch((err) => {
					console.log(`${t("toast.error_updating_ticket")} #${doc._id}:`, err)
					toast(`${t("toast.error_updating_ticket")} #${doc._id}`)
				})
		}
	}, [isEdited, doc, databases.locals.tickets, match, t, user, toast])

	// Add ticket starting time
	const addTimeWhenStarted = () => {
		const timestamp = Math.round(new Date() / 1000).toString()
		let newLogId =
			doc.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								doc.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticket = {
			...doc,
			time_when_started: doc.time_when_started === "" ? timestamp : doc.time_when_started,
			time_when_paused: "",
			log: doc.log ? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: "start" }] : [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: "start" }],
		}
		setDoc(ticket)
		setIsEdited(true)
	}

	// Change ticket time when paused
	const addTimeWhenPaused = () => {
		const timestamp = Math.round(new Date() / 1000).toString()
		let newLogId =
			doc.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								doc.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticket = { ...doc, time_when_paused: timestamp, log: doc.log ? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: "pause" }] : [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: "pause" }] }
		setDoc(ticket)
		setIsEdited(true)
	}

	// Add ticket time when at place
	const addTimeWhenAtPlace = () => {
		const timestamp = Math.round(new Date() / 1000).toString()
		let newLogId =
			doc.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								doc.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticket = {
			...doc,
			time_when_at_place: doc.time_when_at_place === "" ? timestamp : "",
			log: doc.log
				? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: doc.time_when_at_place === "" ? "at_place" : "cancel_at_place" }]
				: [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: doc.time_when_at_place === "" ? "at_place" : "cancel_at_place" }],
		}
		setDoc(ticket)
		setIsEdited(true)
	}

	// Status indicator
	let status
	if (doc.time_when_paused !== "") {
		status = (
			<div className="ticket-row">
				<IonText>
					<h4 className="ion-margin-none">
						{t("tickets.paused")}
						<br />
						<span className="ticket-status-time">
							<DateTime timestamp={doc.time_when_paused} />
						</span>
					</h4>
				</IonText>
				<IonButton onClick={() => addTimeWhenStarted()} size="default" color="primary">
					{t("tickets.continue")}
				</IonButton>
			</div>
		)
	} else if (doc.time_when_documented !== "" && doc.time_when_completed !== "") {
		status = (
			<div className="ticket-row">
				<IonText>
					<h4 className="ion-margin-none">
						{t("tickets.documented")}
						<br />
						<span className="ticket-status-time">
							<DateTime timestamp={doc.time_when_documented} />
						</span>
					</h4>
				</IonText>
			</div>
		)
	} else if (doc.time_when_completed !== "") {
		status = (
			<div className="ticket-row">
				<IonText>
					<h4 className="ion-margin-none">
						{t("tickets.completed")}
						<br />
						<span className="ticket-status-time">
							<DateTime timestamp={doc.time_when_completed} />
						</span>
					</h4>
				</IonText>
			</div>
		)
	} else if (doc.time_when_at_place !== "" && doc.time_when_started === "") {
		status = (
			<div className="ticket-row">
				<IonText>
					<h4 className="ion-margin-none">
						{t("tickets.when_at_place")}
						<br />
						<span className="ticket-status-time">
							<DateTime timestamp={doc.time_when_at_place} />
						</span>
					</h4>
				</IonText>
				<IonButton onClick={() => addTimeWhenStarted()} size="default" color="primary">
					{t("tickets.start")}
				</IonButton>
			</div>
		)
	} else if (doc.time_when_started !== "") {
		status = (
			<div className="ticket-row">
				<IonText>
					<h4 className="ion-margin-none">
						{t("tickets.started")}
						<br />
						<span className="ticket-status-time">
							<DateTime timestamp={doc.time_when_started} />
						</span>
					</h4>
				</IonText>
				<IonButton onClick={() => addTimeWhenPaused()} size="default" color="light">
					{t("tickets.pause")}
				</IonButton>
			</div>
		)
	} else {
		status = (
			<div className="ticket-row">
				<IonText>
					<h4 className="ion-margin-none">{t("tickets.not_started")}</h4>
				</IonText>
				<IonButton onClick={() => addTimeWhenStarted()} size="default" color="primary">
					{t("tickets.start")}
				</IonButton>
			</div>
		)
	}

	// SLA count
	let startSlaCounter = true
	const today = new Date()
	const currentDay = today.getDate()
	const currentMonth = today.getMonth()
	const currentYear = today.getFullYear()
	const currentHour = today.getHours()
	const currentWeekday = today.getDay()

	const dateCreated = new Date(parseInt(doc.time_when_created) * 1000)
	const dayCreated = dateCreated.getDate()
	const monthCreated = dateCreated.getMonth()
	const yearCreated = dateCreated.getFullYear()
	const hourCreated = dateCreated.getHours()
	const weekdayCreated = dateCreated.getDay()

	const isFriday = currentWeekday === 5
	const isSaturday = currentWeekday === 6
	const isSunday = currentWeekday === 0

	const countToMonday = weekdayCreated === 0 ? 1 : 7 - weekdayCreated

	const isCreatedToday = dayCreated === currentDay && monthCreated === currentMonth && yearCreated === currentYear
	const isCreatedYesterday = dayCreated === currentDay - 1 && monthCreated === currentMonth && yearCreated === currentYear
	const isCreatedDayBeforeYesterday = dayCreated === currentDay - 2 && monthCreated === currentMonth && yearCreated === currentYear

	const isCreatedOnWeekend = weekdayCreated === 0 || weekdayCreated === 6 || (doc.sla === 8 && weekdayCreated === 5 && hourCreated >= 17)

	// SLA4 (+1)
	if (doc.sla === 4) {
		dateCreated.setHours(hourCreated + doc.sla + 1)
	}

	// SLA6 (+1), 08:00-22:00
	if (doc.sla === 6) {
		if (isCreatedToday && currentHour >= 22 && hourCreated >= 22) {
			startSlaCounter = false
		} else if (isCreatedToday && currentHour < 8 && hourCreated < 8) {
			startSlaCounter = false
		} else if (isCreatedYesterday && currentHour < 8 && hourCreated >= 22) {
			startSlaCounter = false
		}

		if (hourCreated >= 22) {
			dateCreated.setDate(dayCreated + 1)
			dateCreated.setHours(8 + 1 + doc.sla)
			dateCreated.setMinutes(0)
			dateCreated.setSeconds(0)
		} else if (hourCreated < 8) {
			dateCreated.setHours(8 + 1 + doc.sla)
			dateCreated.setMinutes(0)
			dateCreated.setSeconds(0)
		} else {
			dateCreated.setHours(hourCreated + doc.sla + 1)
		}
	}

	// SLA8 (+1), 08:00-17:00, Mon-Fri
	if (doc.sla === 8) {
		if (isCreatedOnWeekend) {
			if (isFriday && isCreatedToday && currentHour >= 17 && hourCreated >= 17) {
				startSlaCounter = false
			} else if (isSaturday && isCreatedYesterday && hourCreated >= 17) {
				startSlaCounter = false
			} else if (isSaturday && isCreatedToday) {
				startSlaCounter = false
			} else if ((isSunday && isCreatedYesterday) || (isSunday && isCreatedDayBeforeYesterday && hourCreated >= 17)) {
				startSlaCounter = false
			} else if (isSunday && isCreatedToday) {
				startSlaCounter = false
			}
		} else if (isCreatedToday && currentHour >= 17 && hourCreated >= 17) {
			startSlaCounter = false
		} else if (isCreatedToday && currentHour < 8 && hourCreated < 8) {
			startSlaCounter = false
		} else if (isCreatedYesterday && currentHour < 8 && hourCreated >= 17) {
			startSlaCounter = false
		}

		if (isCreatedOnWeekend) {
			dateCreated.setDate(dayCreated + countToMonday)
			dateCreated.setHours(8 + 1 + doc.sla)
			dateCreated.setMinutes(0)
			dateCreated.setSeconds(0)
		} else if (hourCreated >= 17) {
			dateCreated.setDate(dayCreated + 1)
			dateCreated.setHours(8 + 1 + doc.sla)
			dateCreated.setMinutes(0)
			dateCreated.setSeconds(0)
		} else if (hourCreated < 8) {
			dateCreated.setHours(8 + 1 + doc.sla)
			dateCreated.setMinutes(0)
			dateCreated.setSeconds(0)
		} else {
			dateCreated.setHours(hourCreated + doc.sla + 1)
		}
	}

	// Tasks
	const tasks = place?.tasks?.filter((item) => {
		return item.time_when_completed === ""
	})

	return (
		<IonPage>
			<Header title={doc._id ? `${t("tickets.ticket")} #${doc._id}` : ""} backButton />
			<IonContent>
				{doc._id ? (
					<>
						<IonList>
							<IonItem lines="full" color={doc.time_when_paused !== "" || doc.time_when_started === "" ? "" : "success"}>
								<div className="ticket-inner-wrap">
									{status}
									{doc.sla !== "" && doc.time_when_completed === "" && startSlaCounter && (
										<div className="ticket-row ion-margin-top">
											<Countdown
												overtime={true}
												date={dateCreated}
												renderer={({ days, hours, minutes, seconds, completed }) => {
													if (completed) {
														return (
															<IonBadge color="danger">
																SLA +{days ? `${days} d ` : ""}
																{hours ? `${hours} h ` : ""}
																{minutes ? `${minutes} min ` : "0 min"}
															</IonBadge>
														)
													} else {
														return (
															<IonBadge color="warning">
																SLA -{days ? `${days} d ` : ""}
																{hours ? `${hours} h ` : ""}
																{minutes ? `${minutes} min ` : "0 min"}
															</IonBadge>
														)
													}
												}}
											/>
										</div>
									)}

									{doc.sla !== "" && doc.time_when_completed !== "" && (
										<div className="ticket-row ion-margin-top">
											<IonBadge color="light">
												SLA <SlaDiff sla={doc.sla} end={doc.time_when_completed} start={doc.time_when_created} />
											</IonBadge>
										</div>
									)}
								</div>
							</IonItem>
							<IonItem lines="full">
								<div className="inner-wrap">
									<Accordion defaultExpanded>
										<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
											<IonText>
												<h4 className="ion-margin-none">{t("tickets.error")}</h4>
											</IonText>
										</AccordionSummary>
										<AccordionDetails>
											<IonList>
												{doc.error?.id && doc.error?.name && (
													<IonItem lines="none">
														<IonIcon icon={alertCircleOutline} color="danger" slot="start" />
														<IonLabel color="danger">{doc.error.name}</IonLabel>
													</IonItem>
												)}
												{place?.site_id && place?.name && (
													<IonItem lines="none" target="_blank" routerLink={`/places/${place.type}/${place._id}`}>
														<IonIcon icon={locationOutline} color="primary" slot="start" />
														<IonLabel>{doc.place.name}</IonLabel>
														<IonIcon slot="end" icon={chevronForwardOutline} color="medium" />
													</IonItem>
												)}
												{doc.site?.id && doc.site?.name && (
													<IonItem lines="none" target="_blank">
														<IonIcon icon={pinOutline} color="primary" slot="start" />
														<IonLabel>{doc.site.name}</IonLabel>
													</IonItem>
												)}
												{doc.fm_service?.id && doc.fm_service?.name && (
													<IonItem lines="none">
														<IonIcon icon={radioOutline} color="primary" slot="start" />
														<IonLabel>{doc.fm_service.name}</IonLabel>
													</IonItem>
												)}
												{doc.sla !== "" && (
													<IonItem lines="none">
														<IonIcon icon={timeOutline} color="primary" slot="start" />
														<IonLabel>SLA {doc.sla}</IonLabel>
													</IonItem>
												)}
												{doc.more_info !== "" && (
													<IonItem lines="none" className="ion-margin-top">
														<p>{doc.more_info}</p>
													</IonItem>
												)}
											</IonList>
										</AccordionDetails>
									</Accordion>
								</div>
							</IonItem>
							<IonItem lines="full">
								<div className="inner-wrap">
									<Accordion defaultExpanded>
										<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
											<IonText>
												<h4 className="ion-margin-none">{t("tickets.phases")}</h4>
											</IonText>
										</AccordionSummary>
										<AccordionDetails>
											<IonList>
												{doc.error?.id && doc.error?.name && (
													<IonItem lines="none" className="ion-padding-top-half ion-padding-bottom-half ion-border ion-border-radius ion-margin-bottom" routerLink={`/tickets/${doc._id}/accessories`}>
														<div className={doc.time_when_accessories !== "" ? "icon-wrap success" : "icon-wrap primary"} slot="start" color="primary">
															<IonIcon icon={doc.time_when_accessories !== "" ? checkmarkOutline : cartOutline} color="light" style={{ fontSize: "24px" }} />
														</div>
														<IonLabel>
															{t("tickets.accessories")}
															{doc.time_when_accessories !== "" && (
																<>
																	<br />
																	<span className="ticket-status-time">
																		<DateTime timestamp={doc.time_when_accessories} />
																	</span>
																</>
															)}
														</IonLabel>
														<IonIcon slot="end" icon={chevronForwardOutline} color="medium" />
													</IonItem>
												)}
												{place?._id && place?.name && (
													<IonItem lines="none" className="ion-padding-top-half ion-padding-bottom-half ion-border ion-border-radius ion-margin-bottom" button onClick={() => setShowActionSheet(true)}>
														<div className={doc.time_when_at_place !== "" ? "icon-wrap success" : "icon-wrap primary"} slot="start">
															<IonIcon
																icon={doc.time_when_at_place !== "" ? checkmarkOutline : locationOutline}
																color="light"
																style={{
																	fontSize: "24px",
																}}
															/>
														</div>
														<IonLabel>
															{t("tickets.place")}
															{doc.time_when_at_place !== "" && (
																<>
																	<br />
																	<span className="ticket-status-time">
																		<DateTime timestamp={doc.time_when_at_place} />
																	</span>
																</>
															)}
														</IonLabel>
														<IonIcon slot="end" icon={chevronForwardOutline} color="medium" />
													</IonItem>
												)}
												<IonItem lines="none" className="ion-padding-top-half ion-padding-bottom-half ion-border ion-border-radius ion-margin-bottom" routerLink={`/tickets/${doc._id}/maintenance`}>
													<div className={doc.time_when_completed !== "" ? "icon-wrap success" : "icon-wrap primary"} slot="start">
														<IonIcon icon={doc.time_when_completed !== "" ? checkmarkOutline : hammerOutline} color="light" style={{ fontSize: "24px" }} />
													</div>
													<IonLabel>
														{t("tickets.maintenance")}
														{doc.time_when_completed !== "" && (
															<>
																<br />
																<span className="ticket-status-time">
																	<DateTime timestamp={doc.time_when_completed} />
																</span>
															</>
														)}
													</IonLabel>
													<IonIcon slot="end" icon={chevronForwardOutline} color="medium" />
												</IonItem>
												<IonItem lines="none" className="ion-padding-top-half ion-padding-bottom-half ion-border ion-border-radius ion-margin-bottom" routerLink={`/tickets/${doc._id}/tasks`}>
													<div className={tasks?.length === 0 || !tasks ? "icon-wrap success" : "icon-wrap primary"} slot="start">
														<IonIcon icon={tasks?.length === 0 || !tasks ? checkmarkOutline : listOutline} color="light" style={{ fontSize: "24px" }} />
													</div>
													<IonLabel>
														{t("places.tasks")}
														{tasks?.length === 0 ||
															(!tasks && (
																<>
																	<br />
																	<span className="ticket-status-time">{t("places.tasks_done")}</span>
																</>
															))}
													</IonLabel>
													<IonIcon slot="end" icon={chevronForwardOutline} color="medium" />
												</IonItem>
												<IonItem lines="none" routerLink={`/tickets/${doc._id}/documentation`} className="ion-padding-top-half ion-padding-bottom-half ion-border ion-border-radius">
													<div className={doc.time_when_documented !== "" ? "icon-wrap success" : "icon-wrap primary"} slot="start">
														<IonIcon icon={doc.time_when_documented !== "" ? checkmarkOutline : readerOutline} color="light" style={{ fontSize: "24px" }} />
													</div>
													<IonLabel>
														{t("tickets.documentation")}
														{doc.time_when_documented !== "" && (
															<>
																<br />
																<span className="ticket-status-time">
																	<DateTime timestamp={doc.time_when_documented} />
																</span>
															</>
														)}
													</IonLabel>
													<IonIcon slot="end" icon={chevronForwardOutline} color="medium" />
												</IonItem>
											</IonList>
										</AccordionDetails>
									</Accordion>
								</div>
							</IonItem>
						</IonList>
						<IonActionSheet
							isOpen={showActionSheet}
							onDidDismiss={() => setShowActionSheet(false)}
							cssClass="ticket-place-action-sheet"
							header={doc.time_when_at_place !== "" ? "Kohteeseen saavuttu" : "Kohteeseen saapuminen"}
							subHeader={doc.time_when_at_place !== "" ? "Lisätietoa kohteesta löydät kohdesivulta." : "Kuittaa kohteeseen saapuminen. Lisätietoa kohteesta löydät kohdesivulta."}
							buttons={
								doc.time_when_at_place === ""
									? [
											{
												text: t("tickets.show_place"),
												icon: navigateOutline,
												handler: () => {
													props.history.push(`/places/${place.type}/${place?._id}`)
												},
											},
											{
												text: t("tickets.at_place"),
												icon: pinOutline,
												handler: () => {
													addTimeWhenAtPlace()
												},
											},
											{
												text: t("tickets.close"),
												icon: chevronDownOutline,
												role: "cancel",
											},
									  ]
									: [
											{
												text: t("tickets.show_place"),
												icon: navigateOutline,
												handler: () => {
													props.history.push(`/places/${place.type}/${place?._id}`)
												},
											},
											{
												text: t("tickets.cancel"),
												icon: closeOutline,
												handler: () => {
													addTimeWhenAtPlace()
												},
											},
											{
												text: t("tickets.close"),
												icon: chevronDownOutline,
												role: "cancel",
											},
									  ]
							}
						/>
					</>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Ticket
