import React from "react"
import { IonText, IonRow, IonCol, IonGrid, IonIcon, IonItem } from "@ionic/react"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import { chevronDownOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import PictureList from "./PictureList"
import ValueList from "./ValueList"
import _ from "lodash"

const PlaceSystems = (props) => {
	const { t } = useTranslation("link_app")
	const place = props.doc

	return (
		<IonGrid className="ion-padding-none">
			<IonRow style={{ marginBottom: "12px" }}>
				<IonCol size="12">
					{!_.isEmpty(place?.systems) &&
						Object.keys(place?.systems).map((key, i) => {
							return (
								<IonItem key={i} lines="full">
									<div className="inner-wrap">
										<Accordion>
											<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
												<IonText>
													<h5 className="ion-margin-none">{t(`places.${key}`)}</h5>
												</IonText>
											</AccordionSummary>
											<AccordionDetails>
												<PictureList tabName="systems" listName={key} place={place} />
												<ValueList tabName="systems" listName={key} place={place} />
											</AccordionDetails>
										</Accordion>
									</div>
								</IonItem>
							)
						})}
				</IonCol>
			</IonRow>
		</IonGrid>
	)
}
export default PlaceSystems
