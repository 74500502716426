import React, { useState, useContext, useEffect, useCallback } from "react"
import Header from "../components/Header"
import { IonLabel, IonPage, IonContent, IonSegment, IonSegmentButton, IonList } from "@ionic/react"
import TicketListItem from "../components/TicketListItem"
import { DatabaseContext } from "../components/DatabaseContext"
import { useTranslation } from "react-i18next"
import Loading from "../components/Loading"
import { UserContext } from "../components/UserContext"

const Tickets = (props) => {
	const { t } = useTranslation("link_app")
	const [segment, setSegment] = useState("open")
	const { match, isLoading } = props

	// Get user data
	const [user] = useContext(UserContext)

	// Databases
	const databases = useContext(DatabaseContext)

	// Fetching state
	const [isFetching, setIsFetching] = useState(true)

	const getTickets = useCallback(
		(showLoading) => {
			if (showLoading) {
				setIsFetching(true)
			}
			databases.locals.tickets
				.allDocs({
					include_docs: true,
				})
				.then((result) => {
					const docs = result.rows.filter((row) => {
						return !row.id.includes("_design") && row.doc.resourced.findIndex((contact) => contact.id === user.name.replace("temp-", "")) > -1
					})
					setTickets(docs)
					setIsFetching(false)
				})
				.catch((err) => {
					console.log(err)
					setIsFetching(false)
				})
		},
		[databases.locals.tickets, user]
	)

	// Fetch tickets
	const [tickets, setTickets] = useState([])
	useEffect(() => {
		getTickets(true)
	}, [databases.locals.tickets, match, isLoading, user, getTickets])

	// Fetch ticket history
	const [ticketHistory, setTicketHistory] = useState([])
	useEffect(() => {
		databases.locals.ticket_history
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design") && row.doc.resourced.findIndex((contact) => contact.id === user.name) > -1
				})
				setTicketHistory(docs)
				setIsFetching(false)
			})
			.catch((err) => {
				console.log(err)
				setIsFetching(false)
			})
	}, [databases.locals.ticket_history, match, isLoading, user])

	// Create interval for checking tickets if there are no tickets on the list
	useEffect(() => {
		if (tickets.length === 0 && !isFetching) {
			const interval = setInterval(() => {
				console.log("Checking tickets...")
				getTickets(false)
			}, 10000)
			return () => clearInterval(interval)
		}
	}, [tickets, isFetching, getTickets])

	useEffect(() => {
		document.title = `${t("app.ticket_list")} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [t, match])

	return (
		<IonPage>
			<Header title={t("app.ticket_list")} />
			<IonContent>
				{!isFetching ? (
					<>
						<IonSegment
							onIonChange={(e) => {
								e.preventDefault()
								setSegment(e.detail.value)
							}}
							value={segment}
						>
							<IonSegmentButton value="open">
								<IonLabel>
									{t("tickets.open")} ({tickets.length})
								</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="history">
								<IonLabel>
									{t("tickets.history")} ({ticketHistory.length})
								</IonLabel>
							</IonSegmentButton>
						</IonSegment>
						<IonList>
							{segment === "open" && (
								<>
									{tickets
										.sort((a, b) => {
											return parseInt(b.id) - parseInt(a.id)
										})
										.map((item, i) => (
											<TicketListItem key={i} item={item} />
										))}
								</>
							)}
							{segment === "history" && (
								<>
									{ticketHistory
										.sort((a, b) => {
											return parseInt(b.id) - parseInt(a.id)
										})
										.map((item, i) => (
											<TicketListItem historyItem key={i} item={item} />
										))}
								</>
							)}
						</IonList>
					</>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Tickets
