import React from "react"
import { IonLabel, IonText, IonItem, IonIcon, IonBadge } from "@ionic/react"
import { alertCircleOutline, stopCircleOutline, playCircleOutline, pauseCircleOutline, checkmarkDoneCircleOutline, checkmarkCircleOutline, radioOutline } from "ionicons/icons"
import Countdown from "react-countdown"
import { useTranslation } from "react-i18next"
import SlaDiff from "./SlaDiff"

const TicketListItem = (props) => {
	const { t } = useTranslation("link_app")

	// SLA count
	let startSlaCounter = true
	const today = new Date()
	const currentDay = today.getDate()
	const currentMonth = today.getMonth()
	const currentYear = today.getFullYear()
	const currentHour = today.getHours()
	const currentWeekday = today.getDay()

	const dateCreated = new Date(parseInt(props.item.doc.time_when_created) * 1000)
	const dayCreated = dateCreated.getDate()
	const monthCreated = dateCreated.getMonth()
	const yearCreated = dateCreated.getFullYear()
	const hourCreated = dateCreated.getHours()
	const weekdayCreated = dateCreated.getDay()

	const isFriday = currentWeekday === 5
	const isSaturday = currentWeekday === 6
	const isSunday = currentWeekday === 0

	const countToMonday = weekdayCreated === 0 ? 1 : 7 - weekdayCreated

	const isCreatedToday = dayCreated === currentDay && monthCreated === currentMonth && yearCreated === currentYear
	const isCreatedYesterday = dayCreated === currentDay - 1 && monthCreated === currentMonth && yearCreated === currentYear
	const isCreatedDayBeforeYesterday = dayCreated === currentDay - 2 && monthCreated === currentMonth && yearCreated === currentYear

	const isCreatedOnWeekend = weekdayCreated === 0 || weekdayCreated === 6 || (props.item.doc.sla === 8 && weekdayCreated === 5 && hourCreated >= 17)

	// SLA4 (+1)
	if (props.item.doc.sla === 4) {
		dateCreated.setHours(hourCreated + props.item.doc.sla + 1)
	}

	// SLA6 (+1), 08:00-22:00
	if (props.item.doc.sla === 6) {
		if (isCreatedToday && currentHour >= 22 && hourCreated >= 22) {
			startSlaCounter = false
		} else if (isCreatedToday && currentHour < 8 && hourCreated < 8) {
			startSlaCounter = false
		} else if (isCreatedYesterday && currentHour < 8 && hourCreated >= 22) {
			startSlaCounter = false
		}

		if (hourCreated >= 22) {
			dateCreated.setDate(dayCreated + 1)
			dateCreated.setHours(8 + 1 + props.item.doc.sla)
			dateCreated.setMinutes(0)
			dateCreated.setSeconds(0)
		} else if (hourCreated < 8) {
			dateCreated.setHours(8 + 1 + props.item.doc.sla)
			dateCreated.setMinutes(0)
			dateCreated.setSeconds(0)
		} else {
			dateCreated.setHours(hourCreated + props.item.doc.sla + 1)
		}
	}

	// SLA8 (+1), 08:00-17:00, Mon-Fri
	if (props.item.doc.sla === 8) {
		if (isCreatedOnWeekend) {
			if (isFriday && isCreatedToday && currentHour >= 17 && hourCreated >= 17) {
				startSlaCounter = false
			} else if (isSaturday && isCreatedYesterday && hourCreated >= 17) {
				startSlaCounter = false
			} else if (isSaturday && isCreatedToday) {
				startSlaCounter = false
			} else if ((isSunday && isCreatedYesterday) || (isSunday && isCreatedDayBeforeYesterday && hourCreated >= 17)) {
				startSlaCounter = false
			} else if (isSunday && isCreatedToday) {
				startSlaCounter = false
			}
		} else if (isCreatedToday && currentHour >= 17 && hourCreated >= 17) {
			startSlaCounter = false
		} else if (isCreatedToday && currentHour < 8 && hourCreated < 8) {
			startSlaCounter = false
		} else if (isCreatedYesterday && currentHour < 8 && hourCreated >= 17) {
			startSlaCounter = false
		}

		if (isCreatedOnWeekend) {
			dateCreated.setDate(dayCreated + countToMonday)
			dateCreated.setHours(8 + 1 + props.item.doc.sla)
			dateCreated.setMinutes(0)
			dateCreated.setSeconds(0)
		} else if (hourCreated >= 17) {
			dateCreated.setDate(dayCreated + 1)
			dateCreated.setHours(8 + 1 + props.item.doc.sla)
			dateCreated.setMinutes(0)
			dateCreated.setSeconds(0)
		} else if (hourCreated < 8) {
			dateCreated.setHours(8 + 1 + props.item.doc.sla)
			dateCreated.setMinutes(0)
			dateCreated.setSeconds(0)
		} else {
			dateCreated.setHours(hourCreated + props.item.doc.sla + 1)
		}
	}

	return (
		<IonItem lines="full" routerLink={props.historyItem ? false : `/tickets/${props.item?.doc?._id}`}>
			<div className="ticket-inner-wrap">
				<div className="ticket-row">
					<IonText>
						<p className="ion-margin-bottom-half">
							{t("tickets.ticket")} #{props.item?.doc?._id}
						</p>
					</IonText>
					{props.item?.doc?.sla !== "" && (
						<IonText>
							<p className="ion-margin-bottom-half">SLA {props.item?.doc?.sla}</p>
						</IonText>
					)}
				</div>
				<div className="ticket-row ion-margin-bottom">
					{props.item?.doc?.place?.name && (
						<IonText>
							<h5 className="ion-margin-none">{props.item?.doc?.place?.name}</h5>
						</IonText>
					)}
					{props.item?.doc?.sla === "" && "-"}
					{startSlaCounter ? (
						<>
							{props.item?.doc?.sla !== "" && props.item?.doc?.is_active === false && <SlaDiff end={props.item?.doc?.time_when_completed} start={props.item?.doc?.time_when_created} sla={props.item?.doc?.sla} />}
							{props.item?.doc?.sla !== "" && props.item?.doc?.time_when_completed !== "" && props.item?.doc?.is_active === true && (
								<IonBadge color="light">
									<SlaDiff end={props.item?.doc?.time_when_completed} start={props.item?.doc?.time_when_created} sla={props.item?.doc?.sla} />
								</IonBadge>
							)}
							{props.item?.doc?.sla !== "" && props.item?.doc?.time_when_completed === "" && props.item?.doc?.time_when_created !== "" && props.item?.doc?.is_active === false && <IonBadge color="light">{props.item?.doc?.sla}</IonBadge>}
							{props.item?.doc?.sla !== "" && props.item?.doc?.time_when_completed === "" && props.item?.doc?.time_when_created !== "" && props.item?.doc?.is_active === true && (
								<Countdown
									overtime={true}
									date={dateCreated}
									renderer={({ days, hours, minutes, completed }) => {
										if (completed) {
											return (
												<IonBadge color="danger">
													<span className="badge-date">
														+{days ? `${days} d ` : ""}
														{hours ? `${hours} h ` : ""}
														{minutes ? `${minutes} min` : ""}
													</span>
												</IonBadge>
											)
										} else {
											return (
												<IonBadge color="warning">
													<span className="badge-date">
														-{days ? `${days} d ` : ""}
														{hours ? `${hours} h ` : ""}
														{minutes ? `${minutes} min` : ""}
													</span>
												</IonBadge>
											)
										}
									}}
								/>
							)}
						</>
					) : (
						<IonBadge color="light">{props.item?.doc?.sla}</IonBadge>
					)}
				</div>
				{props.item?.doc?.summary !== "" && props.historyItem && (
					<IonText>
						<p className="ion-margin-bottom-half ion-margin-top-half">
							<b>{t("places.log_summary")}: </b>
							{props.item?.doc?.summary}
						</p>
					</IonText>
				)}
				{props.item?.doc?.more_info !== "" && props.historyItem && (
					<IonText>
						<p className="ion-margin-bottom-half ion-margin-top-half">
							<b>{t("places.log_more_info")}: </b>
							{props.item?.doc?.more_info}
						</p>
					</IonText>
				)}
				{props.item?.doc?.error?.id && props.item?.doc?.error?.name && (
					<IonItem lines="none">
						<IonIcon icon={alertCircleOutline} color="danger" slot="start" />
						<IonLabel color="danger">{props.item?.doc?.error?.name}</IonLabel>
					</IonItem>
				)}
				{props.item?.doc?.fm_service.id && props.item?.doc?.fm_service?.name && (
					<IonItem lines="none">
						<IonIcon icon={radioOutline} color="primary" slot="start" />
						<IonLabel>{props.item?.doc?.fm_service?.name}</IonLabel>
					</IonItem>
				)}
				{!props.historyItem && props.item?.doc?.time_when_paused !== "" && (
					<IonItem lines="none">
						<IonIcon icon={pauseCircleOutline} color="warning" slot="start" />
						<IonLabel>{t("tickets.paused")}</IonLabel>
					</IonItem>
				)}
				{!props.historyItem && props.item?.doc?.time_when_documented !== "" && props.item?.doc?.time_when_completed !== "" && (
					<IonItem lines="none">
						<IonIcon icon={checkmarkDoneCircleOutline} color="success" slot="start" />
						<IonLabel>{t("tickets.documented")}</IonLabel>
					</IonItem>
				)}
				{!props.historyItem && props.item?.doc?.time_when_completed !== "" && props.item?.doc?.time_when_documented === "" && (
					<IonItem lines="none">
						<IonIcon icon={checkmarkCircleOutline} color="success" slot="start" />
						<IonLabel>{t("tickets.completed")}</IonLabel>
					</IonItem>
				)}
				{!props.historyItem && props.item?.doc?.time_when_started !== "" && props.item?.doc?.time_when_paused === "" && props.item?.doc?.time_when_documented === "" && props.item?.doc?.time_when_completed === "" && (
					<IonItem lines="none">
						<IonIcon icon={playCircleOutline} color="warning" slot="start" />
						<IonLabel>{t("tickets.started")}</IonLabel>
					</IonItem>
				)}
				{!props.historyItem && props.item?.doc?.time_when_paused === "" && props.item?.doc?.time_when_completed === "" && props.item?.doc?.time_when_started === "" && (
					<IonItem lines="none">
						<IonIcon icon={stopCircleOutline} color="warning" slot="start" />
						<IonLabel>{t("tickets.not_started")}</IonLabel>
					</IonItem>
				)}
			</div>
		</IonItem>
	)
}

export default TicketListItem
