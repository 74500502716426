import React, { useEffect, useState, useContext } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonText, IonList, IonItem, IonIcon, IonLabel } from "@ionic/react"
import { callOutline, chevronForwardOutline, mailOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"

const Contact = (props) => {
	const { t } = useTranslation("link_app")
	const match = props.match

	// Databases
	const databases = useContext(DatabaseContext)

	// Fetch the place
	const [doc, setDoc] = useState({})
	useEffect(() => {
		databases.locals.contacts
			.get(match.params.id)
			.then((result) => {
				setDoc(result)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.contacts, match, props.isLoading])

	useEffect(() => {
		document.title = `${doc.name} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [doc, t, match])

	return (
		<IonPage>
			<Header title={doc.name} backButton />
			<IonContent>
				{doc._id ? (
					<>
						<IonGrid>
							<IonRow>
								<IonCol className="ion-margin-none">
									<div className="ion-avatar-container">
										<div className="contact-avatar ion-margin-bottom">
											<h1 className="ion-margin-none">
												{doc.name !== "" && doc.name.split(" ").length >= 1 ? (
													<>
														{doc.name.split(" ").length >= 1 && doc.name.split(" ")[0].charAt(0)}
														{doc.name.split(" ").length > 1 && doc.name.split(" ")[1].charAt(0)}
													</>
												) : (
													"?"
												)}
											</h1>
										</div>
										<IonText>
											<h4 className="ion-margin-bottom-half">{doc.name}</h4>
										</IonText>
										<IonText>
											<p className="ion-margin-none">{doc.title}</p>
										</IonText>
									</div>
								</IonCol>
							</IonRow>
						</IonGrid>
						<IonList>
							<IonItem lines="none">
								<IonText>
									<h4 className="ion-margin-none">{t("contacts.contact_details")}</h4>
								</IonText>
							</IonItem>

							{doc.phone_number && (
								<IonItem href={`tel:${doc.phone_number}`} lines="none" className="ion-padding-top-half ion-padding-bottom-half">
									<IonIcon icon={callOutline} color="primary" slot="start" />
									<IonLabel>{doc.phone_number}</IonLabel>
									<IonIcon slot="end" icon={chevronForwardOutline} color="primary" />
								</IonItem>
							)}
							{doc.email && (
								<IonItem href={`mailto:${doc.email}`} lines="none" className="ion-padding-top-half ion-padding-bottom-half">
									<IonIcon icon={mailOutline} color="primary" slot="start" />
									<IonLabel>{doc.email}</IonLabel>
									<IonIcon slot="end" icon={chevronForwardOutline} color="primary" />
								</IonItem>
							)}
						</IonList>
					</>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Contact
