import React, { createContext } from "react"
import PouchDB from "pouchdb"

export const DatabaseContext = createContext()

export const DatabaseProvider = (props) => {
	const databases = {
		remotes: {
			accessories: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/accessories" : `https://${window.location.host.replace("app", "db")}/accessories`),
			contacts: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/contacts" : `https://${window.location.host.replace("app", "db")}/contacts`),
			guides: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/guides" : `https://${window.location.host.replace("app", "db")}/guides`),
			places: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/places" : `https://${window.location.host.replace("app", "db")}/places`),
			warehouses: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/warehouses" : `https://${window.location.host.replace("app", "db")}/warehouses`),
			studios: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/studios" : `https://${window.location.host.replace("app", "db")}/studios`),
			tickets: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/tickets" : `https://${window.location.host.replace("app", "db")}/tickets`),
			ticket_history: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/ticket_history" : `https://${window.location.host.replace("app", "db")}/ticket_history`),
			fm_services: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/fm_services" : `https://${window.location.host.replace("app", "db")}/fm_services`),
			users: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/_users" : `https://${window.location.host.replace("app", "db")}/_users`),
			software_presets: new PouchDB(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/software_presets" : `https://${window.location.host.replace("app", "db")}/software_presets`),
		},
		locals: {
			accessories: new PouchDB("accessories", { auto_compaction: true }),
			contacts: new PouchDB("contacts", { auto_compaction: true }),
			guides: new PouchDB("guides", { auto_compaction: true }),
			places: new PouchDB("places", { auto_compaction: true }),
			tickets: new PouchDB("tickets", { auto_compaction: true }),
			warehouses: new PouchDB("warehouses", { auto_compaction: true }),
			studios: new PouchDB("studios", { auto_compaction: true }),
			ticket_history: new PouchDB("ticket_history", { auto_compaction: true }),
			fm_services: new PouchDB("fm_services", { auto_compaction: true }),
			users: new PouchDB("_users", { auto_compaction: true }),
		},
	}

	return <DatabaseContext.Provider value={databases}>{props.children}</DatabaseContext.Provider>
}
