import React, { useState, useContext, useEffect, useCallback } from "react"
import { IonPage, IonContent, IonButton, IonIcon } from "@ionic/react"
import { useTranslation } from "react-i18next"
import { UserContext } from "../components/UserContext"
import { ReactComponent as LogoBlack } from "../assets/logo-black.svg"
import { ReactComponent as LogoWhite } from "../assets/logo-white.svg"
import { eyeOutline, eyeOffOutline } from "ionicons/icons"
import useToast from "../hooks/useToast"
import { AuthContext } from "../components/AuthContext"

const LogIn = () => {
	const { t } = useTranslation("link_app")
	const [, setUser] = useContext(UserContext)
	const [username, setUsername] = useState("")
	const [pw, setPw] = useState("")
	const [isPwVisible, setIsPwVisible] = useState(false)
	const [, setIsAuth] = useContext(AuthContext)
	const toast = useToast()

	const logIn = useCallback(
		async (credentials) => {
			await fetch(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/_session" : `https://${window.location.host.replace("app", "db")}/_session`, {
				method: "POST",
				body: JSON.stringify({
					username: credentials?.username ? credentials.username : username,
					password: credentials?.pw ? credentials.pw : pw,
				}),
				headers: {
					"Content-type": "application/json; charset=UTF-8",
				},
				credentials: "include",
			})
				.then((res) => {
					if (res.ok) {
						setUser({
							name: credentials?.username ? credentials.username : username,
						})
						setIsAuth(true)
						window.sessionStorage.setItem("username", credentials?.username ? credentials.username : username)
						window.location = "/tickets"
					} else {
						toast(t("toast.wrong_username_or_password"))
					}
				})
				.catch((err) => {
					console.log(err)
				})
		},
		[pw, setUser, t, username, setIsAuth, toast]
	)

	useEffect(() => {
		const listener = (e) => {
			if (e.code === "Enter" || e.code === "NumpadEnter") {
				e.preventDefault()
				logIn()
			}
		}
		document.addEventListener("keydown", listener)
		return () => {
			document.removeEventListener("keydown", listener)
		}
	}, [logIn])

	useEffect(() => {
		const token = window.location.search.replace("?auth=", "")
		const user = token.split(":")[0]
		const password = token.split(":")[1]
		if (user && password) {
			const buf = Buffer.from(password, "base64")
			const pw = buf.toString()
			logIn({ username: user, pw: pw })
		}
	}, [logIn])

	useEffect(() => {
		document.title = `${t("app.login")} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [t])

	return (
		<IonPage>
			<IonContent>
				<div className="login">
					<div className="login-wrap ion-text-center">
						<LogoBlack className="ion-margin-bottom-half logo-black" />
						<LogoWhite className="ion-margin-bottom-half logo-white" />
						<p>Maintenance application</p>
						<form
							onSubmit={(e) => {
								e.preventDefault()
								logIn()
							}}
							className="ion-text-center"
						>
							<input className="ion-margin-top normal-padding" value={username} onChange={(e) => setUsername(e.target.value)} autoComplete="off" type="text" placeholder={t("app.username")} />
							<div className="pw-wrap">
								<input value={pw} onChange={(e) => setPw(e.target.value)} autoComplete="off" type={isPwVisible ? "text" : "password"} placeholder={t("app.password")} />
								<IonButton onClick={() => setIsPwVisible(!isPwVisible)} fill="clear" color="medium">
									<IonIcon style={{ fontSize: "22px" }} slot="icon-only" icon={isPwVisible ? eyeOffOutline : eyeOutline}></IonIcon>
								</IonButton>
							</div>
							<IonButton type="submit">{t("app.login")}</IonButton>
						</form>
					</div>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default LogIn
