import React, { useContext } from "react"
import { trashOutline } from "ionicons/icons"
import { IonButton, IonItem, IonLabel, IonIcon, IonCard, IonCardContent } from "@ionic/react"
import DateTime from "./DateTime"
import { useTranslation } from "react-i18next"
import { UserContext } from "./UserContext"

const CommentCard = (props) => {
	const { t } = useTranslation("link_app")
	const [user] = useContext(UserContext)

	return (
		<IonCard className="ion-border">
			<IonItem lines="none" className="ion-margin-top-half ion-margin-bottom-half">
				<div className="icon-wrap primary" slot="start">
					{props.contact && props.contact.doc && props.contact.doc.name !== "" && props.contact.doc.name.split(" ").length >= 1 ? (
						<>
							{props.contact.doc.name.split(" ").length >= 1 && props.contact.doc.name.split(" ")[0].charAt(0)}
							{props.contact.doc.name.split(" ").length > 1 && props.contact.doc.name.split(" ")[1].charAt(0)}
						</>
					) : (
						"?"
					)}
				</div>
				<IonLabel>
					{props.contact ? props.contact.doc.name : t("app.deleted_contact")}
					<br />
					<span className="comment-date">
						<DateTime timestamp={props.comment.time} />
					</span>
				</IonLabel>
				<IonButton className="no-margin" disabled={props.contact?.doc?._id === user.name ? false : true} slot="end" onClick={() => props.handleCommentRemove(props.comment._id)} size="small" fill="clear" color="medium">
					<IonIcon slot="icon-only" icon={trashOutline} />
				</IonButton>
			</IonItem>
			<IonCardContent className="ion-padding-none ion-margin-right ion-margin-bottom ion-margin-left">{props.comment.text}</IonCardContent>
		</IonCard>
	)
}

export default CommentCard
