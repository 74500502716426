import React from "react"
import { IonLoading } from "@ionic/react"
import { useTranslation } from "react-i18next"

const Loading = () => {
	const { t } = useTranslation("link_app")
	return <IonLoading message={t("app.loading")} cssClass="loading" isOpen showBackdrop={false} />
}

export default Loading
