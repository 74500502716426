import React from "react"
import { chevronDownOutline, alertCircleOutline, checkmarkOutline, checkmarkCircleOutline } from "ionicons/icons"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { IonText, IonIcon, IonButton, IonItem } from "@ionic/react"
import DateTime from "./DateTime"

const Tasks = (props) => {
	const { doc, setDoc, showNotCompleted, setIsEdited } = props
	const { t } = useTranslation("link_app")

	const handleDone = (taskId) => {
		const timestamp = Math.round(new Date() / 1000).toString()
		const placeCopy = { ...doc }
		const index = placeCopy.tasks.findIndex((task) => task.id === taskId)
		if (index > -1 && placeCopy?.tasks[index]?.time_when_completed === "") {
			placeCopy.tasks[index] = { ...placeCopy.tasks[index], time_when_completed: timestamp }
			setDoc(placeCopy)
			setIsEdited(true)
		}
	}

	const tasks = doc?.tasks?.filter((item) => {
		if (showNotCompleted) {
			return item.time_when_completed === ""
		} else return item
	})

	return (
		<>
			{tasks?.length > 0 ? (
				tasks?.map((item) => {
					return (
						<IonItem key={item.id} lines="full">
							<div className="inner-wrap">
								<Accordion>
									<AccordionSummary className="task-accordion-summary" expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
										<IonText className="ion-margin-right">
											<p className="ion-margin-none task-title">{item.title}</p>
										</IonText>

										<div className="task-timestamp-wrap">
											<div className="timestamp-icon-wrap">
												<IonIcon className="ion-margin-right-half" style={{ fontSize: "20px" }} icon={alertCircleOutline} color="danger" slot="start" />
												<IonText className="ion-margin-right" style={{ display: "flex", alignItems: "center" }}>
													<p className="ion-margin-none">
														<DateTime timestamp={item.time_when_created} />
													</p>
												</IonText>
											</div>
											{item.time_when_completed && (
												<div className="timestamp-icon-wrap">
													<IonIcon className="ion-margin-right-half" style={{ fontSize: "20px" }} icon={checkmarkCircleOutline} color="success" slot="start" />
													<IonText style={{ display: "flex", alignItems: "center" }}>
														<p className="ion-margin-none">
															<DateTime timestamp={item.time_when_completed} />
														</p>
													</IonText>
												</div>
											)}
										</div>
									</AccordionSummary>
									<AccordionDetails>
										<div className="ion-padding-top">
											{item.title !== "" && (
												<IonText>
													<p className="ion-padding-left-half ion-margin-none ion-margin-bottom">
														<b>{t("places.tasks_title")}: </b>
														{item?.title}
													</p>
												</IonText>
											)}
											{item.description !== "" && (
												<IonText>
													<p className="ion-padding-left-half ion-margin-none ion-margin-bottom-half">
														<b>{t("places.task_description")}: </b>
														{item?.description}
													</p>
												</IonText>
											)}
											{item.time_when_completed === "" && (
												<IonButton className="ion-margin-none" fill="clear" color="success" onClick={() => handleDone(item.id)}>
													<IonIcon color="success" icon={checkmarkOutline} slot="start" />
													{t("places.mark_done")}
												</IonButton>
											)}
										</div>
									</AccordionDetails>
								</Accordion>
							</div>
						</IonItem>
					)
				})
			) : (
				<IonText color="success">
					<p className="ion-margin-none ion-padding">{t("places.tasks_done")}</p>
				</IonText>
			)}
		</>
	)
}

export default Tasks
