import React, { useState, useEffect, useContext } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonList, IonItem, IonImg } from "@ionic/react"
import Loading from "../components/Loading"
import { DatabaseContext } from "../components/DatabaseContext"
import _ from "lodash"

const PlaceAttachment = (props) => {
	const match = props.match
	const selectionType = match.params.type

	// Databases
	const databases = useContext(DatabaseContext)

	// Fetch the ticket
	const [doc, setDoc] = useState({})
	useEffect(() => {
		switch (selectionType) {
			case "site":
				databases.locals.places
					.get(match.params.id, { attachments: true })
					.then((result) => {
						setDoc(result)
					})
					.catch((err) => {
						console.log(err)
					})
				break
			case "warehouse":
				databases.locals.warehouses
					.get(match.params.id, { attachments: true })
					.then((result) => {
						setDoc(result)
					})
					.catch((err) => {
						console.log(err)
					})
				break
			case "studio":
				databases.locals.studios
					.get(match.params.id, { attachments: true })
					.then((result) => {
						setDoc(result)
					})
					.catch((err) => {
						console.log(err)
					})
				break
			case "fm-service":
				databases.locals.fm_services
					.get(match.params.id, { attachments: true })
					.then((result) => {
						setDoc(result)
					})
					.catch((err) => {
						console.log(err)
					})
				break
			default:
		}
	}, [databases, match, props.isLoading, selectionType])

	useEffect(() => {
		document.title = `${match.params.attachmentId} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link Management - Telemast`
	}, [match])

	// Get attachment
	let attachment
	if (!_.isEmpty(doc._attachments)) {
		attachment = doc._attachments[match.params.attachmentId]
	}

	return (
		<IonPage>
			<Header title={match.params.attachmentId} backButton />
			<IonContent>
				{attachment ? (
					<IonList>
						<IonItem lines="none">
							<div className="inner-wrap">{attachment.content_type && attachment.data && <IonImg src={`data:${attachment.content_type};base64,${attachment.data}`} alt="" />}</div>
						</IonItem>
					</IonList>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default PlaceAttachment
