import React, { useEffect, useState, useContext, useCallback } from "react"
import "./App.css"
import { IonApp, setupIonicReact } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"
import { UserContext } from "./components/UserContext"
import { DatabaseContext } from "./components/DatabaseContext"
import { AuthContext } from "./components/AuthContext"
import Tabs from "./components/Tabs"
import _ from "lodash"
import LogIn from "./pages/LogIn"
import { useStatus } from "@capacitor-community/react-hooks/network"
import { useServiceWorker } from "./components/ServiceWorkerContext"

// Core CSS required for Ionic components to work properly
import "@ionic/react/css/core.css"

// Basic CSS for apps built with Ionic
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

// Optional CSS utils that can be commented out
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

// Theme variables
import "./theme/variables.css"

// Theme setup
setupIonicReact({
	rippleEffect: false,
	mode: "md", // Lock the material design mode
})

const App = () => {
	// Databases
	const databases = useContext(DatabaseContext)

	// Loading state for updating the UI
	const [isTicketsLoading, setIsTicketsLoading] = useState(false)
	const [isPlacesLoading, setIsPlacesLoading] = useState(false)
	const [isContactsLoading, setIsContactsLoading] = useState(false)
	const [isAccessoriesLoading, setIsAccessoriesLoading] = useState(false)

	// Update Service Worker on launch
	const serviceWorker = useServiceWorker()
	const getRegistration = useCallback(async () => {
		if ("serviceWorker" in navigator) {
			const promise = Promise.resolve(await navigator.serviceWorker.getRegistration())
			promise
				.then((result) => {
					console.log("Service Worker registration: ", result)
					if (result && result.waiting) {
						serviceWorker.updateAssets()
					}
				})
				.catch((err) => console.log(err))
		}
	}, [serviceWorker])

	useEffect(() => {
		if (serviceWorker) {
			getRegistration()
		}
	}, [getRegistration, serviceWorker])

	// IsAuth
	const [isAuth, setIsAuth] = useContext(AuthContext)

	// Network status
	const { networkStatus } = useStatus()
	const [status, setStatus] = useState(null)
	useEffect(() => {
		if (networkStatus?.connected === true) {
			setStatus(true)
		} else {
			setStatus(false)
		}
	}, [networkStatus])

	// Fetch user data
	const [user, setUser] = useContext(UserContext)
	const username = window.sessionStorage.getItem("username")
	useEffect(() => {
		if (username && _.isEmpty(user)) {
			setUser({ name: username })
		}
	}, [username, user, setUser])

	// Fetch user metadata
	useEffect(() => {
		if (user.name && _.isEmpty(user.metadata)) {
			databases.locals.contacts
				.get(username)
				.then((result) => {
					setUser({
						name: username,
						roles: result.roles,
						metadata: {
							...user.metadata,
							firstname_lastname: result.name,
							email: result.email,
							phone_number: result.phone_number,
							title: result.title,
							company: result.company,
							area: result.area,
							auto_mode: result.auto_mode,
							dark_mode: result.dark_mode,
						},
					})
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}, [user, setUser, databases, isContactsLoading, username])

	// Switch between light and dark mode
	useEffect(() => {
		if (user.metadata) {
			if (user.metadata.auto_mode) {
				const prefersDark = window.matchMedia("(prefers-color-scheme: dark)")
				if (prefersDark.matches) {
					document.body.classList.add("dark")
				} else {
					document.body.classList.remove("dark")
				}
			} else if (user.metadata.dark_mode) {
				document.body.classList.add("dark")
			} else if (!user.metadata.dark_mode) {
				document.body.classList.remove("dark")
			}
		}
	}, [user])

	// Documents to delete locally
	const [ticketsToDelete, setTicketsToDelete] = useState([])
	// Set tickets to delete
	useEffect(() => {
		if (!_.isEmpty(user)) {
			databases.locals.tickets.allDocs({ include_docs: true }).then((result) => {
				const docs = result.rows.filter((row) => {
					return (!row.id.includes("_design") && row.doc.resourced.findIndex((contact) => contact.id === user.name.replace("temp-", "")) === -1) || row.doc.is_active === false
				})
				setTicketsToDelete(docs)
			})
		}
	}, [user, isTicketsLoading, databases])

	// Remove unneccessary tickets from local database
	useEffect(() => {
		if (databases.locals.tickets && ticketsToDelete.length > 0 && !_.isEmpty(user)) {
			const docs = ticketsToDelete.map((row) => {
				return {
					_id: row.doc._id,
					_rev: row.doc._rev,
					_deleted: true,
				}
			})
			databases.locals.tickets.bulkDocs(docs).then((res) => {
				if (res.ok) {
					setTicketsToDelete([])
				}
			})
		}
	}, [ticketsToDelete, databases.locals.tickets, user, isTicketsLoading])

	// Sync databases
	useEffect(() => {
		if (user.name) {
			databases.remotes.tickets.replicate
				.to(databases.locals.tickets, {
					live: true,
					retry: true,
				})
				.on("change", (change) => {
					setIsTicketsLoading(true)
					setTimeout(() => setIsTicketsLoading(false), 1000)
				})
				.on("error", (err) => {
					console.log("Unhandled error in tickets db:", err)
					if (err.status === 401) {
						setIsAuth(false)
					}
				})

			databases.locals.tickets.setMaxListeners(20)
			databases.locals.tickets.replicate
				.to(databases.remotes.tickets, {
					live: true,
					retry: true,
					filter: (doc) => {
						return !doc._deleted
					},
				})
				.on("change", (change) => {
					setIsTicketsLoading(true)
					setTimeout(() => setIsTicketsLoading(false), 1000)
				})
				.on("error", (err) => {
					console.log("Unhandled error in tickets db:", err)
					if (err.status === 401) {
						setIsAuth(false)
					}
				})

			databases.locals.places.setMaxListeners(20)
			databases.locals.places
				.sync(databases.remotes.places, {
					live: true,
					retry: true,
				})
				.on("change", (change) => {
					setIsPlacesLoading(true)
					setTimeout(() => setIsPlacesLoading(false), 1000)
				})
				.on("error", (err) => {
					console.log("Unhandled error in places db:", err)
					if (err.status === 401) {
						setIsAuth(false)
					}
				})

			databases.locals.warehouses.setMaxListeners(20)
			databases.locals.warehouses
				.sync(databases.remotes.warehouses, {
					live: true,
					retry: true,
				})
				.on("change", (change) => {
					setIsPlacesLoading(true)
					setTimeout(() => setIsPlacesLoading(false), 1000)
				})
				.on("error", (err) => {
					console.log("Unhandled error in warehouses db:", err)
					if (err.status === 401) {
						setIsAuth(false)
					}
				})

			databases.locals.studios.setMaxListeners(20)
			databases.locals.studios
				.sync(databases.remotes.studios, {
					live: true,
					retry: true,
				})
				.on("change", (change) => {
					setIsPlacesLoading(true)
					setTimeout(() => setIsPlacesLoading(false), 1000)
				})
				.on("error", (err) => {
					console.log("Unhandled error in studios db:", err)
					if (err.status === 401) {
						setIsAuth(false)
					}
				})

			databases.locals.contacts.setMaxListeners(20)
			databases.locals.contacts
				.sync(databases.remotes.contacts, {
					live: true,
					retry: true,
				})
				.on("change", (change) => {
					setIsContactsLoading(true)
					setTimeout(() => setIsContactsLoading(false), 1000)
				})
				.on("error", (err) => {
					console.log("Unhandled error in contacts db:", err)
					if (err.status === 401) {
						setIsAuth(false)
					}
				})

			databases.remotes.accessories.replicate
				.to(databases.locals.accessories, {
					live: true,
					retry: true,
				})
				.on("change", (change) => {
					setIsAccessoriesLoading(true)
					setTimeout(() => setIsAccessoriesLoading(false), 1000)
				})
				.on("error", (err) => {
					console.log("Unhandled error in accessories db:", err)
					if (err.status === 401) {
						setIsAuth(false)
					}
				})

			databases.remotes.ticket_history.replicate
				.to(databases.locals.ticket_history, {
					live: true,
					retry: true,
				})
				.on("change", (change) => {
					setIsTicketsLoading(true)
					setTimeout(() => setIsTicketsLoading(false), 1000)
				})
				.on("error", (err) => {
					console.log("Unhandled error in ticket history db:", err)
					if (err.status === 401) {
						setIsAuth(false)
					}
				})

			databases.remotes.fm_services.replicate
				.to(databases.locals.fm_services, {
					live: true,
					retry: true,
				})
				.on("change", (change) => {
					setIsPlacesLoading(true)
					setTimeout(() => setIsPlacesLoading(false), 1000)
				})
				.on("error", (err) => {
					console.log("Unhandled error in fm services db:", err)
					if (err.status === 401) {
						setIsAuth(false)
					}
				})
		}
	}, [databases, user, setIsAuth])

	return (
		<IonApp>
			<IonReactRouter>
				{!_.isEmpty(user) && isAuth && <Tabs isTicketsLoading={isTicketsLoading} isPlacesLoading={isPlacesLoading} isContactsLoading={isContactsLoading} isAccessoriesLoading={isAccessoriesLoading} />}
				{!_.isEmpty(user) && !isAuth && status === false && <Tabs isTicketsLoading={isTicketsLoading} isPlacesLoading={isPlacesLoading} isContactsLoading={isContactsLoading} isAccessoriesLoading={isAccessoriesLoading} />}
				{!_.isEmpty(user) && !isAuth && status === true && <LogIn />}
				{_.isEmpty(user) && <LogIn />}
			</IonReactRouter>
		</IonApp>
	)
}

export default App
