import { useIonToast } from "@ionic/react"

const useToast = () => {
	const [present] = useIonToast()

	const toast = async (message) => {
		return present({
			duration: 5000,
			message,
		})
	}

	return toast
}

export default useToast
