import React, { useState, useEffect, useContext } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonList, IonText, IonItem, IonImg } from "@ionic/react"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"
import { useTranslation } from "react-i18next"

const Manual = (props) => {
	// Misc vars
	const { match, isLoading } = props
	const { t } = useTranslation("link_app")

	// Databases
	const databases = useContext(DatabaseContext)

	// Fetch the accessory
	const [accessory, setAccessory] = useState({})
	useEffect(() => {
		databases.locals.accessories
			.get(match.params.id, { attachments: true })
			.then((result) => {
				setAccessory(result)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.accessories, match, isLoading])

	// Get manual
	const manual = accessory?.manuals?.find((manual) => manual?.id === match?.params?.manualId)

	useEffect(() => {
		document.title = `${t("maintenance.manual")} ${accessory.name} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [accessory, t, match])

	return (
		<IonPage>
			<Header title={accessory.name && manual ? `${accessory.name} ${manual.title}` : ""} backButton />
			<IonContent>
				{accessory._id && manual ? (
					<IonList>
						<IonItem lines="full">
							<div className="ion-padding-top ion-padding-bottom">
								{manual?.title && (
									<IonText>
										<h4>{manual.title}</h4>
									</IonText>
								)}
								{manual?.description && (
									<IonText>
										<p>{manual.description}</p>
									</IonText>
								)}
							</div>
						</IonItem>
						<IonItem lines="full">
							<div className="ticket-inner-wrap">
								<ol>
									{manual?.phases?.map((phase, i) => {
										let attachment
										if (accessory._attachments && phase.attachment_id !== "") {
											attachment = accessory._attachments[phase.attachment_id]
										}
										const text = phase.text?.replace(/\bhttps?:\/\/\S+/gi, "")
										const videoUrls = phase.text?.match(/\bhttps?:\/\/\S+/gi)
										return (
											<li key={i}>
												{text && (
													<IonText>
														<p>{text}</p>
													</IonText>
												)}
												{videoUrls?.map((url, i) => {
													return (
														<div className="responsive-iframe-container ion-margin-bottom" key={i}>
															<iframe title={`video-${i}`} className="responsive-iframe" src={url} />
														</div>
													)
												})}
												{attachment && <IonImg className="ion-margin-bottom" src={`data:${attachment.content_type};base64,${attachment.data}`} alt="" />}
											</li>
										)
									})}
								</ol>
							</div>
						</IonItem>
					</IonList>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Manual
