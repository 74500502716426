import React, { useContext, useEffect } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonText, IonList, IonItem, IonIcon, IonLabel, IonToggle } from "@ionic/react"
import { UserContext } from "../components/UserContext"
import { callOutline, mailOutline, businessOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import Loading from "../components/Loading"
import { DatabaseContext } from "../components/DatabaseContext"
import useToast from "../hooks/useToast"
//import generator from "generate-password"

const User = (props) => {
	const { t } = useTranslation("link_app")
	const [user, setUser] = useContext(UserContext)
	const toast = useToast()
	const match = props.match

	// Databases
	const databases = useContext(DatabaseContext)

	// New PW state
	// const [isPwActive, setIsPwActive] = useState(false)
	// const [newPw, setNewPw] = useState("")
	// useEffect(() => {
	// 	setNewPw(
	// 		generator.generate({
	// 			length: 24,
	// 			numbers: true,
	// 			symbols: true,
	// 			lowercase: true,
	// 			uppercase: true,
	// 		})
	// 	)
	// }, [match, isPwActive])

	const handleAutoModeChange = () => {
		setUser({
			...user,
			metadata: {
				...user.metadata,
				auto_mode: !user.metadata.auto_mode,
			},
		})
		databases.locals.contacts
			.get(user.name)
			.then((result) => {
				const doc = {
					...result,
					auto_mode: !result.auto_mode,
				}
				return databases.locals.contacts.put(doc)
			})
			.then(() => {
				console.log(t("toast.user_updated"))
				toast(t("toast.user_updated"))
			})
			.catch((err) => {
				console.log(t("toast.error_updating_user:", err))
				toast(t("toast.error_updating_user"))
			})
	}

	const handleModeChange = () => {
		setUser({
			...user,
			metadata: {
				...user.metadata,
				dark_mode: !user.metadata.dark_mode,
			},
		})
		databases.locals.contacts
			.get(user.name)
			.then((result) => {
				const doc = {
					...result,
					dark_mode: !result.dark_mode,
				}
				return databases.locals.contacts.put(doc)
			})
			.then(() => {
				console.log(t("toast.user_updated"))
				toast(t("toast.user_updated"))
			})
			.catch((err) => {
				console.log(t("toast.error_updating_user:", err))
				toast(t("toast.error_updating_user"))
			})
	}

	// const handlePWChange = () => {
	// 	if (isPwActive && newPw.length >= 12) {
	// 		databases.locals.users
	// 			.get(`org.couchdb.user:${user.name}`)
	// 			.then((result) => {
	// 				const editedUser = { ...result, password: newPw }
	// 				return databases.users.put(editedUser)
	// 			})
	// 			.then((result) => {
	// 				setIsPwActive(false)
	// 				console.log(t("toast.user_updated"))
	// 				toast(t("toast.user_updated"))
	// 				setTimeout(() => window.location.reload(), 1000)
	// 			})
	// 			.catch((err) => {
	// 				console.log(err)
	// 			})
	// 	} else if (isPwActive && newPw.length < 12) {
	// 		toast(t("toast.password_too_short"))
	// 	}
	// }

	useEffect(() => {
		document.title = `${t("nav.profile")} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [t, match])

	return (
		<IonPage>
			<Header title={t("nav.profile")} backButton />
			<IonContent>
				{user.metadata ? (
					<>
						<IonGrid>
							<IonRow>
								<IonCol className="ion-margin-none">
									<div className="ion-avatar-container">
										<div className="contact-avatar ion-margin-bottom">
											<h1 className="ion-margin-none">
												{user.metadata.firstname_lastname && user.metadata.firstname_lastname !== "" && user.metadata.firstname_lastname.split(" ").length >= 1 ? (
													<>
														{user.metadata.firstname_lastname.split(" ").length >= 1 && user.metadata.firstname_lastname.split(" ")[0].charAt(0)}
														{user.metadata.firstname_lastname.split(" ").length > 1 && user.metadata.firstname_lastname.split(" ")[1].charAt(0)}
													</>
												) : (
													"?"
												)}
											</h1>
										</div>
										<IonText>
											<h4 className="ion-margin-bottom-half">{user.metadata.firstname_lastname}</h4>
										</IonText>
										<IonText>
											<p className="ion-margin-none">{user.metadata.title}</p>
										</IonText>
									</div>
								</IonCol>
							</IonRow>
						</IonGrid>
						<IonList>
							<IonItem lines="none" className="ion-margin-top">
								<IonText>
									<h4 className="ion-margin-none">{t("user.own_details")}</h4>
								</IonText>
							</IonItem>
							{user.metadata.phone_number && (
								<IonItem lines="none" className="ion-padding-top-half ion-padding-bottom-half">
									<IonIcon icon={callOutline} color="primary" slot="start" />
									<IonLabel>{user.metadata.phone_number}</IonLabel>
								</IonItem>
							)}
							{user.metadata.email && (
								<IonItem lines="none" className="ion-padding-top-half ion-padding-bottom-half">
									<IonIcon icon={mailOutline} color="primary" slot="start" />
									<IonLabel>{user.metadata.email}</IonLabel>
								</IonItem>
							)}
							{user.metadata.company && (
								<IonItem lines="none" className="ion-padding-top-half ion-padding-bottom-half">
									<IonIcon icon={businessOutline} color="primary" slot="start" />
									<IonLabel>{user.metadata.company}</IonLabel>
								</IonItem>
							)}
							{/* <IonItem lines="none" className="ion-margin-top">
								<IonText>
									<h4 className="ion-margin-none">{t("user.login_details")}</h4>
								</IonText>
							</IonItem> */}
							{/* {!isPwActive && (
								<IonItem
									button
									lines="none"
									className="ion-padding-top-half ion-padding-bottom-half"
									onClick={() => {
										setIsPwActive(true)
									}}
								>
									<IonIcon icon={lockClosedOutline} color="primary" slot="start" className="ion-margin-right" />
									<IonLabel>{t("user.change_password")}</IonLabel>
								</IonItem>
							)} */}
							{/* {isPwActive && (
								<IonItem lines="none" className="ion-margin-bottom-half">
									<IonLabel>
										<div className="ion-margin-bottom-half">
											<label htmlFor="new-pw">{t("user.new_password")}</label>
										</div>
										<input
											id="new-pw"
											type="text"
											value={newPw}
											onChange={(e) => {
												setNewPw(e.target.value)
											}}
										/>
										{newPw.length < 12 && (
											<IonText className="input-error-text" color="danger">
												{t("toast.password_too_short")}
											</IonText>
										)}
									</IonLabel>
									<div className="ion-margin-top">
										{newPw.length >= 12 && (
											<IonButton slot="end" fill="solid" size="default" color="success" className="ion-margin-none ion-margin-left ion-margin-top-half" onClick={() => setIsPwActive(false)}>
												<IonIcon slot="icon-only" icon={saveOutline} />
											</IonButton>
										)}
										<IonButton slot="end" fill="clear" className="ion-margin-none ion-margin-left-half ion-margin-top-half" onClick={() => setIsPwActive(false)}>
											<IonIcon slot="icon-only" color="medium" icon={closeOutline} />
										</IonButton>
									</div>
								</IonItem>
							)} */}
							<IonItem lines="none" className="ion-margin-top">
								<IonText>
									<h4 className="ion-margin-none">{t("user.app_appearance")}</h4>
								</IonText>
							</IonItem>
							<IonItem lines="none" className="ion-padding-top-half ion-padding-bottom-half">
								<IonToggle checked={user.metadata.auto_mode} disabled={user.metadata.dark_mode} onIonChange={() => handleAutoModeChange()} />
								<IonLabel>{t("user.automatic")}</IonLabel>
							</IonItem>
							<IonItem lines="none" className="ion-padding-top-half ion-padding-bottom-half">
								<IonToggle checked={user.metadata.dark_mode} disabled={user.metadata.auto_mode} onIonChange={() => handleModeChange()} />
								<IonLabel>{t("user.dark_mode")}</IonLabel>
							</IonItem>
						</IonList>
					</>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default User
