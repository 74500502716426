import React, { useState, useContext, useEffect } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonList, IonText, IonItem, IonImg } from "@ionic/react"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"
import _ from "lodash"

const Attachment = (props) => {
	const match = props.match

	// Databases
	const databases = useContext(DatabaseContext)

	// Fetch the ticket
	const [doc, setDoc] = useState({})
	useEffect(() => {
		databases.locals.tickets
			.get(match.params.id, { attachments: true })
			.then((result) => {
				setDoc(result)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.tickets, match, props.isLoading])

	// Get attachment
	let attachment
	if (doc.images && doc.images.length > 0 && doc._attachments && !_.isEmpty(doc._attachments)) {
		attachment = doc._attachments[match.params.attachmentId]
	}

	// Get caption
	let text
	if (doc.images && doc.images.length > 0) {
		text = doc.images[doc.images.findIndex((image) => image.attachment_id === match.params.attachmentId)]
	}

	useEffect(() => {
		document.title = `${match.params.attachmentId} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link Management - Telemast`
	}, [match])

	return (
		<IonPage>
			<Header title={match.params.attachmentId} backButton />
			<IonContent>
				{attachment ? (
					<IonList>
						<IonItem lines="none">
							<div className="ticket-inner-wrap">
								<IonImg className="ion-margin-bottom" src={`data:${attachment.content_type};base64,${attachment.data}`} alt="" />
								{text && (
									<IonText>
										<p>{text.text}</p>
									</IonText>
								)}
							</div>
						</IonItem>
					</IonList>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Attachment
