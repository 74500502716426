import React, { useState } from "react"
import { IonText, IonRow, IonCol, IonGrid, IonIcon, IonList, IonItem, IonLabel, IonSegment, IonSegmentButton } from "@ionic/react"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import { chevronDownOutline, checkmarkCircleOutline, alertCircleOutline, pinOutline, volumeHighOutline, peopleOutline } from "ionicons/icons"
import DateTime from "./DateTime"
import { useTranslation } from "react-i18next"
import Tasks from "./Tasks"

const PlaceLog = (props) => {
	const { t } = useTranslation("link_app")
	const { doc, setDoc, setIsEdited } = props

	const [detailValue, setDetailValue] = useState(1)

	const handleChange = (newValue) => {
		setDetailValue(parseInt(newValue, 10))
	}

	return (
		<>
			<IonGrid className="ion-padding-none">
				<IonRow style={{ marginBottom: "12px" }}>
					<IonCol size="12">
						<IonSegment onIonChange={(e) => handleChange(e.detail.value)} value={detailValue}>
							<IonSegmentButton value="1">
								<IonLabel>{t("tickets.history")}</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="2">
								<IonLabel>{t("places.tasks")}</IonLabel>
							</IonSegmentButton>
						</IonSegment>
						{detailValue === 1 && (
							<div>
								{props.ticketHistory?.map((item, i) => {
									return (
										<IonItem key={i} lines="full">
											<div className="inner-wrap">
												<Accordion>
													<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
														<IonIcon className="ion-margin-right-half" style={{ fontSize: "20px" }} icon={alertCircleOutline} color="danger" slot="start" />
														<IonText className="ion-margin-right" style={{ display: "flex", alignItems: "center" }}>
															<p className="ion-margin-none">
																<DateTime timestamp={item.doc?.time_when_created} />
															</p>
														</IonText>
														{item.doc?.time_when_completed && (
															<>
																<IonIcon className="ion-margin-right-half" style={{ fontSize: "20px" }} icon={checkmarkCircleOutline} color="success" slot="start" />
																<IonText style={{ display: "flex", alignItems: "center" }}>
																	<p className="ion-margin-none">
																		<DateTime timestamp={item.doc?.time_when_completed} />
																	</p>
																</IonText>
															</>
														)}
													</AccordionSummary>
													<AccordionDetails>
														{item?.doc?.summary !== "" && (
															<IonText>
																<p className="ion-margin-none ion-margin-bottom-half">
																	<b>{t("places.log_summary")}: </b>
																	{item?.doc?.summary}
																</p>
															</IonText>
														)}
														<br />
														{item?.doc?.more_info !== "" && (
															<IonText>
																<p className="ion-margin-none ion-margin-bottom-half">
																	<b>{t("places.log_more_info")}: </b>
																	{item?.doc?.more_info}
																</p>
															</IonText>
														)}
														<IonList>
															{item?.doc?.error?.id && item?.doc?.error?.name && (
																<IonItem lines="none">
																	<IonIcon icon={alertCircleOutline} color="danger" slot="start" />
																	<IonLabel color="danger">{item?.doc?.error?.name}</IonLabel>
																</IonItem>
															)}
															{item?.doc?.site?.id && item?.doc?.site?.name && (
																<IonItem lines="none" target="_blank">
																	<IonIcon icon={pinOutline} color="primary" slot="start" />
																	<IonLabel>{item?.doc?.site?.name}</IonLabel>
																</IonItem>
															)}
															{item?.doc?.fm_service?.id && item?.doc?.fm_service?.name && (
																<IonItem lines="none">
																	<IonIcon icon={volumeHighOutline} color="primary" slot="start" />
																	<IonLabel>{item?.doc?.fm_service?.name}</IonLabel>
																</IonItem>
															)}
															{item.doc?.resourced?.length > 0 && (
																<IonItem lines="none">
																	<IonIcon icon={peopleOutline} color="primary" slot="start" />
																	<IonLabel>
																		{item.doc.resourced.map((contact, i) => {
																			return `${contact?.name}${item.doc?.resourced?.length === i + 1 ? "" : ", "}`
																		})}
																	</IonLabel>
																</IonItem>
															)}
														</IonList>
													</AccordionDetails>
												</Accordion>
											</div>
										</IonItem>
									)
								})}
							</div>
						)}
						{detailValue === 2 && <Tasks doc={doc} setDoc={setDoc} setIsEdited={setIsEdited} />}
					</IonCol>
				</IonRow>
			</IonGrid>
		</>
	)
}
export default PlaceLog
