import React, { createContext, useState, useMemo, useEffect, useContext } from "react"
import * as serviceWorker from "../serviceWorkerRegistration"

const ServiceWorkerContext = createContext()

function ServiceWorkerProvider(props) {
	const [waitingServiceWorker, setWaitingServiceWorker] = useState(null)
	const [assetsUpdateReady, setAssetsUpdateReady] = useState(false)
	const [assetsCached, setAssetsCached] = useState(false)

	const value = useMemo(
		() => ({
			assetsUpdateReady,
			assetsCached,
			// Call when the user confirm update of application and reload page
			updateAssets: () => {
				if (waitingServiceWorker) {
					waitingServiceWorker.addEventListener("statechange", (event) => {
						if (event.target.state === "activated") {
							window.location.reload()
						}
					})

					waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
				}
			},
		}),
		[assetsUpdateReady, assetsCached, waitingServiceWorker]
	)

	// Once on component mounted subscribe to Update and Succes events in
	// CRA's service worker wrapper
	useEffect(() => {
		serviceWorker.register({
			onUpdate: (registration) => {
				setWaitingServiceWorker(registration.waiting)
				setAssetsUpdateReady(true)
			},
			onSuccess: () => {
				setAssetsCached(true)
			},
		})
	}, [])

	return <ServiceWorkerContext.Provider value={value} {...props} />
}

function useServiceWorker() {
	const context = useContext(ServiceWorkerContext)

	if (!context) {
		throw new Error("useServiceWorker must be used within a ServiceWorkerProvider")
	}

	return context
}

export { ServiceWorkerProvider, useServiceWorker }
