import React, { useState, useContext } from "react"
import { IonHeader, IonToolbar, IonTitle, IonBackButton, IonButtons, IonIcon, IonButton, IonPopover, IonContent, IonList, IonItem, IonLabel, IonText } from "@ionic/react"
import { ellipsisVertical } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import { personCircleOutline, logOutOutline, cloudOfflineOutline, lockClosedOutline } from "ionicons/icons"
import { UserContext } from "../components/UserContext"
import { useStatus } from "@capacitor-community/react-hooks/network"
import useToast from "../hooks/useToast"
import { AuthContext } from "./AuthContext"
import packageJson from "../../package.json"
import { buildInfo } from "../buildInfo"

const Header = (props) => {
	const [showMenu, setShowMenu] = useState(null)
	const { t } = useTranslation("link_app")
	const toast = useToast()

	// Get current user from user context
	const [user, setUser] = useContext(UserContext)

	// Network status
	const { networkStatus } = useStatus()

	// IsAuth
	const [isAuth] = useContext(AuthContext)

	// Handle the language selection
	// const setAppLang = (language) => {
	// 	if (language === "fi") {
	// 		i18n.changeLanguage("fi")
	// 	} else if (language === "en") {
	// 		i18n.changeLanguage("en")
	// 	}
	// 	document.documentElement.lang = language
	// }

	const logOut = async () => {
		await fetch(window.location.host.includes("localhost") ? "https://db.linkapp-staging.taiste.fi/_session" : `https://${window.location.host.replace("app", "db")}/_session`, {
			method: "DELETE",
			credentials: "include",
		})
			.then((res) => {
				window.sessionStorage.removeItem("username")
				return res.json()
			})
			.then((res) => {
				console.log(res)
				document.body.classList.remove("dark")
				setUser({})
				toast(t("toast.logged_out"))
				setTimeout(() => window.location.reload(), 1000)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	return (
		<>
			<IonHeader>
				{!networkStatus?.connected && (
					<div className="no-connection-notice">
						<IonIcon className="ion-margin-right-half" icon={cloudOfflineOutline} />
						{t("app.no_connection")}
					</div>
				)}
				{!isAuth && (
					<div className="no-connection-notice">
						<IonIcon className="ion-margin-right-half" icon={lockClosedOutline} />
						{t("app.no_auth")}
					</div>
				)}
				<IonToolbar>
					{props.backButton && (
						<IonButtons slot="start">
							<IonBackButton defaultHref="/tickets" />
						</IonButtons>
					)}
					<IonTitle>{props.title}</IonTitle>
					<IonButtons slot="end">
						<IonButton
							className="menu-button"
							onClick={(e) => {
								e.preventDefault()
								setShowMenu(e)
							}}
							style={{ height: "unset", fontSize: "16px", fontWeight: "400" }}
						>
							<div className="profile" slot="start">
								{user.metadata && user.metadata.firstname_lastname && user.metadata.firstname_lastname !== "" && user.metadata.firstname_lastname.split(" ").length >= 1 ? (
									<>
										{user.metadata.firstname_lastname.split(" ").length >= 1 && user.metadata.firstname_lastname.split(" ")[0].charAt(0)}
										{user.metadata.firstname_lastname.split(" ").length > 1 && user.metadata.firstname_lastname.split(" ")[1].charAt(0)}
									</>
								) : (
									"?"
								)}
							</div>
							<IonIcon slot="end" icon={ellipsisVertical} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonPopover event={showMenu} isOpen={!!showMenu} onDidDismiss={() => setShowMenu(null)}>
				<IonContent>
					<IonList>
						<IonItem
							onClick={() => {
								setShowMenu(null)
							}}
							routerLink={`/user`}
							lines="full"
						>
							<IonLabel
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<IonIcon icon={personCircleOutline} slot="start" className="ion-margin-right-half" style={{ fontSize: "22px" }} />
								{t("nav.profile")}
							</IonLabel>
						</IonItem>
						<IonItem
							onClick={() => {
								logOut()
								setShowMenu(null)
							}}
							button
							lines="full"
						>
							<IonLabel
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<IonIcon icon={logOutOutline} slot="start" className="ion-margin-right-half" style={{ fontSize: "22px" }} />
								{t("nav.log_out")}
							</IonLabel>
						</IonItem>

						{/* {i18n.language === "en" ? (
							<IonItem
								onClick={() => {
									setAppLang("fi")
								}}
								button
								lines="none"
							>
								<IonLabel
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<IonIcon icon={globeOutline} slot="start" className="ion-margin-right-half" style={{ fontSize: "22px" }} />
									Suomeksi
								</IonLabel>
							</IonItem>
						) : (
							<IonItem
								onClick={() => {
									setAppLang("en")
								}}
								button
								lines="none"
							>
								<IonLabel
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<IonIcon icon={globeOutline} slot="start" className="ion-margin-right-half" style={{ fontSize: "22px" }} />
									In English
								</IonLabel>
							</IonItem>
						)} */}
					</IonList>
					<div className="build-info">
						<IonText>
							<p>version: {packageJson.version}</p>
						</IonText>
						{buildInfo?.buildDate && (
							<IonText>
								<p>build date: {buildInfo.buildDate}</p>
							</IonText>
						)}
						{buildInfo?.buildNumber && (
							<IonText>
								<p>build number: {buildInfo.buildNumber}</p>
							</IonText>
						)}
					</div>
				</IonContent>
			</IonPopover>
		</>
	)
}

export default Header
