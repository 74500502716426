import React, { useState, useContext, useEffect, useCallback } from "react"
import Header from "../components/Header"
import { IonPage, IonContent, IonList, IonText, IonItem, IonIcon, IonLabel } from "@ionic/react"
import { readerOutline, chevronForwardOutline } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"

const Maintenance = (props) => {
	// Misc vars
	const { match, isLoading } = props
	const { t } = useTranslation("link_app")

	// Databases
	const databases = useContext(DatabaseContext)

	// Fetching state
	const [isFetching, setIsFetching] = useState(true)

	const getAccessories = useCallback(() => {
		setIsFetching(true)
		databases.locals.accessories
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				console.log(result)
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				setAccessories(docs)
				setIsFetching(false)
			})
			.catch((err) => {
				console.log(err)
				setIsFetching(false)
			})
	}, [databases.locals.accessories])

	// Fetch tickets
	const [accessories, setAccessories] = useState([])
	useEffect(() => {
		getAccessories()
	}, [databases.locals.accessories, match, isLoading, getAccessories])

	useEffect(() => {
		document.title = `${t("app.manuals")} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [t, match])

	return (
		<IonPage>
			<Header title={t("header.manuals")} backButton />
			<IonContent>
				{!isFetching ? (
					<IonList>
						<IonItem lines="full">
							<div className="ticket-inner-wrap">
								{accessories?.map((accessory, i) => {
									if (accessory.doc?.manuals?.length > 0) {
										return (
											<div key={i}>
												<IonText>
													<p className="ion-margin-none ion-margin-top">{accessory.doc?.name}</p>
												</IonText>
												<IonList className="ion-margin-top">
													{accessory.doc?.manuals?.map((manual, i) => {
														return (
															<IonItem key={i} lines="none" routerLink={`/accessories/${accessory.doc?._id}/manuals/${manual?.id}`}>
																<IonIcon icon={readerOutline} color="primary" slot="start" />
																<IonLabel>{manual.title}</IonLabel>
																<IonIcon slot="end" icon={chevronForwardOutline} color="primary" />
															</IonItem>
														)
													})}
												</IonList>
											</div>
										)
									} else return null
								})}
							</div>
						</IonItem>
					</IonList>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Maintenance
