import React from "react"
import { folderOpenOutline, locationOutline, peopleOutline, readerOutline } from "ionicons/icons"
import { IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonRouterOutlet } from "@ionic/react"
import { Redirect, Route } from "react-router-dom"
import Tickets from "../pages/Tickets"
import Ticket from "../pages/Ticket"
import Places from "../pages/Places"
import Place from "../pages/Place"
import PlaceAttachment from "../pages/PlaceAttachment"
import Contacts from "../pages/Contacts"
import Contact from "../pages/Contact"
import Documentation from "../pages/Documentation"
import Accessories from "../pages/Accessories"
import AccessoryAttachment from "../pages/AccessoryAttachment"
import Maintenance from "../pages/Maintenance"
import Attachment from "../pages/Attachment"
import User from "../pages/User"
import Manual from "../pages/Manual"
import LogIn from "../pages/LogIn"
import { useTranslation } from "react-i18next"
import TicketTasks from "../pages/TicketTasks"
import Manuals from "../pages/Manuals"

const Tabs = (props) => {
	const { t } = useTranslation("link_app")
	const { isTicketsLoading, isPlacesLoading, isContactsLoading, isAccessoriesLoading } = props

	return (
		<IonTabs>
			<IonTabBar slot="bottom">
				<IonTabButton tab="tickets" href="/tickets">
					<IonIcon icon={folderOpenOutline} />
					<IonLabel>{t("app.ticket_list")}</IonLabel>
				</IonTabButton>
				<IonTabButton tab="places" href="/places">
					<IonIcon icon={locationOutline} />
					<IonLabel>{t("app.map")}</IonLabel>
				</IonTabButton>
				<IonTabButton tab="manuals" href="/manuals">
					<IonIcon icon={readerOutline} />
					<IonLabel>{t("app.manuals")}</IonLabel>
				</IonTabButton>
				<IonTabButton tab="contacts" href="/contacts">
					<IonIcon icon={peopleOutline} />
					<IonLabel>{t("app.contacts")}</IonLabel>
				</IonTabButton>
			</IonTabBar>
			<IonRouterOutlet>
				<Route
					path="/tickets"
					exact
					render={(props) => {
						return <Tickets {...props} isLoading={isTicketsLoading} />
					}}
				/>
				<Route
					path="/tickets/:id"
					exact
					render={(props) => {
						return <Ticket {...props} isLoading={isTicketsLoading} isPlacesLoading={isPlacesLoading} />
					}}
				/>
				<Route
					path="/tickets/:id/documentation"
					exact
					render={(props) => {
						return <Documentation {...props} isLoading={isTicketsLoading} />
					}}
				/>
				<Route
					path="/tickets/:id/accessories"
					exact
					render={(props) => {
						return <Accessories {...props} isLoading={isTicketsLoading} />
					}}
				/>
				<Route
					path="/tickets/:id/maintenance"
					exact
					render={(props) => {
						return <Maintenance {...props} isLoading={isTicketsLoading} />
					}}
				/>
				<Route
					path="/tickets/:id/tasks"
					exact
					render={(props) => {
						return <TicketTasks {...props} isLoading={isPlacesLoading} />
					}}
				/>
				<Route
					path="/places"
					exact
					render={(props) => {
						return <Places {...props} isLoading={isPlacesLoading} />
					}}
				/>
				<Route
					path="/places/:type/:id"
					exact
					render={(props) => {
						return <Place {...props} isLoading={isPlacesLoading} />
					}}
				/>
				<Route
					path="/places/:type/:id/attachments/:attachmentId"
					exact
					render={(props) => {
						return <PlaceAttachment {...props} isLoading={isPlacesLoading} />
					}}
				/>
				<Route
					path="/contacts"
					exact
					render={(props) => {
						return <Contacts {...props} isLoading={isContactsLoading} />
					}}
				/>
				<Route
					path="/contacts/:id"
					exact
					render={(props) => {
						return <Contact {...props} isLoading={isContactsLoading} />
					}}
				/>
				<Route
					path="/tickets/:id/attachments/:attachmentId"
					exact
					render={(props) => {
						return <Attachment {...props} isLoading={isTicketsLoading} />
					}}
				/>
				<Route
					path="/user"
					exact
					render={(props) => {
						return <User {...props} isLoading={isContactsLoading} />
					}}
				/>
				<Route
					path="/accessories/:id/manuals/:manualId"
					exact
					render={(props) => {
						return <Manual {...props} isLoading={isAccessoriesLoading} />
					}}
				/>
				<Route
					path="/accessories/:id/attachments/:attachmentId"
					exact
					render={(props) => {
						return <AccessoryAttachment {...props} isLoading={isAccessoriesLoading} />
					}}
				/>
				<Route
					path="/login"
					exact
					render={(props) => {
						return <LogIn />
					}}
				/>
				<Route
					path="/manuals"
					exact
					render={(props) => {
						return <Manuals {...props} isLoading={isAccessoriesLoading} />
					}}
				/>
				<Redirect from="/" to="/tickets" exact />
			</IonRouterOutlet>
		</IonTabs>
	)
}

export default Tabs
