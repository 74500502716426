const convert = (coord) => {
	// deg + min/60 + sec / 3600
	if (coord && coord.length > 0) {
		const deg = parseFloat(coord.substring(0, 2))
		const min = parseFloat(coord.substring(3, 5) / 60)
		const sec = parseFloat(coord.substring(5, 7) / 3600)
		const result = deg + min + sec
		return result
	} else return ""
}

export default convert
