import React, { useState, useEffect } from "react"
import { IonText, IonItem, IonRow, IonCol, IonGrid } from "@ionic/react"

const ValueList = (props) => {
	const [tabName, setTabName] = useState("")
	const [listName, setListName] = useState("")
	useEffect(() => {
		if (props.tabName) {
			setTabName(props.tabName)
		}
		if (props.listName) {
			setListName(props.listName)
		}
	}, [props])

	let values = []
	if (props.place[tabName] && props.place[tabName][listName]) {
		values = props.place[tabName][listName].values
	}

	return (
		<IonGrid className="ion-padding-none">
			<IonRow>
				<IonCol className="ion-margin-none">
					{tabName !== "" &&
						listName !== "" &&
						values?.length > 0 &&
						values?.map((value, i) => {
							return (
								<IonItem key={i} lines="none">
									<IonCol size="6" className="ion-padding-right-half ion-margin-none">
										<IonText color="medium">{value.name}</IonText>
									</IonCol>
									<IonCol size="6" className="ion-padding-left-half ion-margin-none">
										<IonText>{value.text}</IonText>
									</IonCol>
								</IonItem>
							)
						})}
				</IonCol>
			</IonRow>
		</IonGrid>
	)
}
export default ValueList
