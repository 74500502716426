import React, { useState, useContext, useEffect } from "react"
import Header from "../components/Header"
import { Link } from "react-router-dom"
import { IonPage, IonContent, IonSegment, IonSegmentButton, IonLabel, IonIcon, IonModal, IonButton } from "@ionic/react"
import ReactMapGL, { NavigationControl, Marker, GeolocateControl, StaticMap } from "react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { ReactComponent as MarkerIcon } from "../assets/marker.svg"
import { playCircleOutline, stopCircleOutline, pauseCircleOutline, checkmarkCircleOutline, checkmarkDoneCircleOutline, radioOutline, cube, musicalNotes } from "ionicons/icons"
import { useTranslation } from "react-i18next"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"
import useWindowSize from "../hooks/useWindowSize"
import convert from "../convert"
import _ from "lodash"
import TicketListItem from "../components/TicketListItem"

// Workaround for map not showing in production build. See more at https://github.com/mapbox/mapbox-gl-js/issues/10173 and https://github.com/visgl/react-map-gl/pull/1365
import mapboxgl from "mapbox-gl"
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default

const Places = (props) => {
	const { t } = useTranslation("link_app")
	const match = props.match
	const [, height] = useWindowSize()
	const [modal, setModal] = useState("")

	// Databases
	const databases = useContext(DatabaseContext)

	// Fetching state
	const [isFetching, setIsFetching] = useState(true)

	// Fetch tickets
	const [tickets, setTickets] = useState([])
	useEffect(() => {
		databases.locals.tickets
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				if (docs.length === 0) {
					setSegment("places")
				}
				setTickets(docs)
				setIsFetching(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.tickets, match, props.isLoading])

	// Fetch places
	const [places, setPlaces] = useState([])
	useEffect(() => {
		databases.locals.places
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				setPlaces(docs)
				setIsFetching(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.places, match, props.isLoading])

	// Fetch studios
	const [studios, setStudios] = useState([])
	useEffect(() => {
		databases.locals.studios
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				setStudios(docs)
				setIsFetching(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.studios, match, props.isLoading])

	// Fetch warehouses
	const [warehouses, setWarehouses] = useState([])
	useEffect(() => {
		databases.locals.warehouses
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				setWarehouses(docs)
				setIsFetching(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.warehouses, match, props.isLoading])

	const [mapStyle, setMapStyle] = useState("default")
	const [segment, setSegment] = useState("open")

	const [viewport, setViewport] = useState({
		longitude: 25.7587,
		latitude: 63.978652,
		zoom: 8,
	})

	useEffect(() => {
		if (tickets.length > 0) {
			const placesCopy = [...places]
			const studiosCopy = [...studios]
			const place = placesCopy.concat(studiosCopy).find((place) => place.doc?._id === tickets[0].doc?.place?.id)
			const long = convert(place?.doc?.coordinates[0])
			const lat = convert(place?.doc?.coordinates[1])

			if (long !== "" && lat !== "") {
				setViewport({
					zoom: 8,
					longitude: long,
					latitude: lat,
				})
			}
		}
	}, [tickets, places, studios])

	const MAPBOX_TOKEN = "pk.eyJ1Ijoib2xsaWthcmtrYWluZW4iLCJhIjoiY2tpN2NjMGxwMzNjZjMwbHR4Y2tjbWtrcyJ9.pXEKiASHCH_D4hzCaquTyw"

	useEffect(() => {
		document.title = `${t("app.map")} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link Management - Telemast`
	}, [t, match])

	// Group tickets by site id
	const groupedTickets = _.groupBy(tickets, (item) => item.doc?.place?.site_id)

	return (
		<IonPage>
			<Header title={t("app.map")} />
			<IonContent>
				{!isFetching ? (
					<>
						<IonSegment
							onIonChange={(e) => {
								setSegment(e.detail.value)
							}}
							value={segment}
							scrollable
						>
							<IonSegmentButton value="open">
								<IonLabel>
									{t("places.open")} ({tickets.length})
								</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="masts">
								<IonLabel>{t("places.masts")}</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="studios">
								<IonLabel>{t("places.studios")}</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="warehouses">
								<IonLabel>{t("places.warehouses")}</IonLabel>
							</IonSegmentButton>
						</IonSegment>

						<ReactMapGL
							className="places-map"
							{...viewport}
							width="100vw"
							height={`${height - 180}px`}
							style={{ position: "relative" }}
							onViewportChange={setViewport}
							mapboxApiAccessToken={MAPBOX_TOKEN}
							mapStyle={mapStyle === "default" ? "mapbox://styles/mapbox/outdoors-v11" : "mapbox://styles/mapbox/satellite-v9"}
						>
							{segment === "open" && (
								<>
									{Object.values(groupedTickets)
										.sort((a, b) => {
											return parseInt(b.id) - parseInt(a.id)
										})
										.map((item, i) => {
											const ticket = item[0]
											const placesCopy = [...places]
											const studiosCopy = [...studios]
											const place = placesCopy.concat(studiosCopy).find((place) => place?.doc?.site_id === ticket?.doc?.place?.site_id)

											if (item.length > 1 && place?.doc?.coordinates && place?.doc?.coordinates[0] !== "" && place?.doc?.coordinates[1] !== "") {
												return (
													<Marker key={`ticket-${ticket.doc._id}-${item.length}`} longitude={convert(place.doc.coordinates[0])} latitude={convert(place.doc.coordinates[1])} offsetLeft={-20} offsetTop={-48}>
														<button className="marker-list-indicator" onClick={() => setModal(`modal-${i + 1}`)}>
															<p>{item.length}</p>
														</button>
													</Marker>
												)
											} else if (place?.doc?.coordinates && place?.doc?.coordinates[0] !== "" && place?.doc?.coordinates[1] !== "") {
												return (
													<Marker key={`ticket-${ticket.doc._id}`} longitude={convert(place.doc.coordinates[0])} latitude={convert(place.doc.coordinates[1])} offsetLeft={-24} offsetTop={-60}>
														<div className="marker-inner-wrap">
															<Link to={`/tickets/${ticket.doc._id}`}>
																<MarkerIcon doc={ticket?.doc} className="map-marker danger" />
																<div className="marker-status">
																	{ticket.doc.time_when_paused !== "" && <IonIcon className="marker-icon" color="light" icon={pauseCircleOutline} />}
																	{ticket.doc.time_when_documented !== "" && ticket.doc.time_when_completed !== "" && <IonIcon className="marker-icon" color="light" icon={checkmarkDoneCircleOutline} />}
																	{ticket.doc.time_when_completed !== "" && ticket.doc.time_when_documented === "" && <IonIcon className="marker-icon" color="light" icon={checkmarkCircleOutline} />}
																	{ticket.doc.time_when_started !== "" && ticket.doc.time_when_paused === "" && ticket.doc.time_when_completed === "" && ticket.doc.time_when_documented === "" && (
																		<IonIcon className="marker-icon" color="light" icon={playCircleOutline} />
																	)}
																	{ticket.doc.time_when_paused === "" && ticket.doc.time_when_completed === "" && ticket.doc.time_when_started === "" && <IonIcon className="marker-icon" color="light" icon={stopCircleOutline} />}
																</div>
																<div className="marker-title">
																	<p className={mapStyle === "default" ? "map" : "sat"}>{place.doc.name}</p>
																	<p className={mapStyle === "default" ? "map" : "sat"}>Työ #{ticket.doc._id}</p>
																</div>
															</Link>
														</div>
													</Marker>
												)
											} else return null
										})}
								</>
							)}
							{segment === "masts" && (
								<>
									{places.map((place, i) => {
										if (place.doc?.coordinates && place.doc?.coordinates[0] !== "" && place.doc?.coordinates[1] !== "") {
											return (
												<Marker key={`mast-${place.doc._id}`} longitude={convert(place.doc.coordinates[0])} latitude={convert(place.doc.coordinates[1])} offsetLeft={-24} offsetTop={-60}>
													<div className="marker-inner-wrap">
														<Link to={`/places/${place.doc.type}/${place.doc._id}`}>
															<MarkerIcon className="map-marker danger" />
															<div className="marker-status">
																<IonIcon className="marker-icon" color="light" icon={radioOutline} />
															</div>
															<div className="marker-title">
																<p className={mapStyle === "default" ? "map" : "sat"}>{place.doc.name}</p>
															</div>
														</Link>
													</div>
												</Marker>
											)
										} else return null
									})}
								</>
							)}
							{segment === "studios" && (
								<>
									{studios.map((studio) => {
										if (studio.doc?.coordinates && studio.doc?.coordinates[0] !== "" && studio.doc?.coordinates[1] !== "") {
											return (
												<Marker key={`studio-${studio.doc._id}-${studio.doc._rev}`} longitude={convert(studio.doc.coordinates[0])} latitude={convert(studio.doc.coordinates[1])} offsetLeft={-24} offsetTop={-60}>
													<div className="marker-inner-wrap">
														<Link to={`/places/${studio.doc.type}/${studio.doc._id}`}>
															<MarkerIcon className="map-marker primary" />
															<div className="marker-status">
																<IonIcon className="marker-icon" color="light" icon={musicalNotes} />
															</div>
															<div className="marker-title">
																<p className={mapStyle === "default" ? "map" : "sat"}>{studio.doc.name}</p>
															</div>
														</Link>
													</div>
												</Marker>
											)
										} else return null
									})}
								</>
							)}
							{segment === "warehouses" && (
								<>
									{warehouses.map((warehouse) => {
										if (warehouse.doc?.coordinates && warehouse.doc?.coordinates[0] !== "" && warehouse.doc?.coordinates[1] !== "") {
											return (
												<Marker key={`warehouse-${warehouse.doc._id}-${warehouse.doc._rev}`} longitude={convert(warehouse.doc.coordinates[0])} latitude={convert(warehouse.doc.coordinates[1])} offsetLeft={-24} offsetTop={-60}>
													<div className="marker-inner-wrap">
														<Link to={`/places/${warehouse.doc.type}/${warehouse.doc._id}`}>
															<MarkerIcon className="map-marker warning" />
															<div className="marker-status">
																<IonIcon className="marker-icon" color="dark" icon={cube} />
															</div>
															<div className="marker-title">
																<p className={mapStyle === "default" ? "map" : "sat"}>{warehouse.doc.name}</p>
															</div>
														</Link>
													</div>
												</Marker>
											)
										} else return null
									})}
								</>
							)}
							<NavigationControl style={{ top: "16px", right: "16px" }} showCompass={false} />
							<GeolocateControl
								style={{ bottom: "58px", right: "16px" }}
								positionOptions={{
									enableHighAccuracy: true,
								}}
								trackUserLocation={true}
								auto={false}
							/>
							<div
								style={{
									position: "absolute",
									bottom: "116px",
									right: "16px",
								}}
							>
								<div className="mapboxgl-ctrl mapboxgl-ctrl-group overflow-hidden">
									<button
										onClick={() => {
											mapStyle === "default" ? setMapStyle("satellite") : setMapStyle("default")
										}}
										className="mapboxgl-ctrl-map-style"
									>
										<StaticMap {...viewport} width="40px" height="40px" onViewportChange={setViewport} mapboxApiAccessToken={MAPBOX_TOKEN} mapStyle={mapStyle === "satellite" ? "mapbox://styles/mapbox/outdoors-v11" : "mapbox://styles/mapbox/satellite-v9"} />
										{mapStyle === "default" ? <p>sat</p> : <p>map</p>}
									</button>
								</div>
							</div>
						</ReactMapGL>
					</>
				) : (
					<Loading />
				)}

				{Object.values(groupedTickets).map((item, i) => {
					return (
						<IonModal key={i} isOpen={modal === `modal-${i + 1}`} onDidDismiss={() => setModal("")} cssClass="marker-list-modal">
							<div className="modal-inner-wrap">
								{item
									.sort((a, b) => {
										return parseInt(b.id) - parseInt(a.id)
									})
									.map((ticket, i) => {
										return (
											<span key={i} onClick={() => setModal("")}>
												<TicketListItem key={i} item={ticket} />
											</span>
										)
									})}
							</div>
							<IonButton color="danger" onClick={() => setModal("")}>
								{t("app.close")}
							</IonButton>
						</IonModal>
					)
				})}
			</IonContent>
		</IonPage>
	)
}

export default Places
