import React, { useState, useEffect } from "react"

const MoreInfo = (props) => {
	// Get tab & list names
	const [tabName, setTabName] = useState("")
	const [listName, setListName] = useState("")
	useEffect(() => {
		if (props.tabName && props.listName) {
			setTabName(props.tabName)
			setListName(props.listName)
		}
	}, [props])

	return (
		<>
			<div className="ion-margin-top ion-margin-left">{tabName && listName && <p>{props.place[tabName] && props.place[tabName][listName] ? props.place[tabName][listName].comment : ""}</p>}</div>
		</>
	)
}
export default MoreInfo
