import React from "react"
import { chevronDownOutline } from "ionicons/icons"
import { IonList, IonText, IonItem, IonIcon } from "@ionic/react"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import PictureList from "./PictureList"
import ValueList from "./ValueList"

const StudioServices = (props) => {
	return (
		<IonList>
			{Object.keys(props.doc?.services).map((key, i) => {
				const service = props.doc?.services[key]
				return (
					<IonItem lines="full" key={i}>
						<div className="inner-wrap">
							<Accordion>
								<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
									<IonText>
										<h5 className="ion-margin-none">{service?.name}</h5>
									</IonText>
								</AccordionSummary>
								<AccordionDetails>
									<PictureList place={props.doc} tabName="services" listName={key} />
									<ValueList place={props.doc} tabName="services" listName={key} />
								</AccordionDetails>
							</Accordion>
						</div>
					</IonItem>
				)
			})}
		</IonList>
	)
}

export default StudioServices
