import { DateTime as Luxon } from "luxon"

const SlaDiff = ({ start, end, sla }) => {
	const startTime = Luxon.fromSeconds(parseInt(start))
	const endTime = Luxon.fromSeconds(parseInt(end))
	const countToMonday = startTime.weekday === 0 ? 1 : 7 - startTime.weekday
	const isCreatedFriday = startTime.weekday === 5
	const isCreatedSaturday = startTime.weekday === 6
	const isCreatedSunday = startTime.weekday === 0

	let slaTime = startTime

	// SLA4 (+1)
	if (sla === 4) {
		slaTime = startTime.plus({ hours: sla + 1 })
	}

	// SLA6 (+1), 08:00-22:00
	// If start time is between 22 and 8, set starting time hours 8 + SLA + 1
	if (sla === 6) {
		if (startTime?.c?.hour >= 22) {
			slaTime = startTime.plus({ days: 1, hours: 24 - startTime.c.hour + 8 + sla + 1 }).minus({ minutes: startTime.c.minute, seconds: startTime.c.second })
		} else if (startTime?.c?.hour < 8) {
			slaTime = startTime.plus({ hours: 8 - startTime.c.hour + sla + 1 }).minus({ minutes: startTime.c.minute, seconds: startTime.c.second })
		} else {
			slaTime = startTime.plus({ hours: sla + 1 })
		}
	}

	// SLA8 (+1), 08:00-17:00, Mon-Fri
	// If start time is between 22 and 8, set starting time hours 8 + SLA + 1
	if (sla === 8) {
		if ((isCreatedFriday && startTime.c.hour >= 17) || isCreatedSaturday || isCreatedSunday) {
			slaTime = startTime.plus({ days: countToMonday, hours: 24 - startTime.c.hour + 8 + sla + 1 }).minus({ minutes: startTime.c.minute, seconds: startTime.c.second })
		} else if (startTime?.c?.hour >= 17) {
			slaTime = startTime.plus({ days: 1, hours: 24 - startTime.c.hour + 8 + sla + 1 }).minus({ minutes: startTime.c.minute, seconds: startTime.c.second })
		} else if (startTime?.c?.hour < 8) {
			slaTime = startTime.plus({ hours: 8 - startTime.c.hour + sla + 1 }).minus({ minutes: startTime.c.minute, seconds: startTime.c.second })
		} else {
			slaTime = startTime.plus({ hours: sla + 1 })
		}
	}

	//console.log("ID: " + id + ", SLA: " + sla + ", SLA TIME:" + JSON.stringify(slaTime) + ", END TIME:" + JSON.stringify(endTime))

	const diff = endTime.diff(slaTime, ["days", "hours", "minutes"])
	const overtime = slaTime >= endTime

	return (
		diff.invalid === null && (
			<>
				{overtime ? "-" : "+"}
				{`${diff.values?.days ? `${diff.values?.days?.toString()?.replace("-", "")} d ` : ""}${diff.values?.hours ? `${diff.values?.hours?.toString()?.replace("-", "")} h ` : ""}${diff.values?.minutes ? `${parseInt(diff.values?.minutes)?.toString()?.replace("-", "")} min` : ""}`}
			</>
		)
	)
}

export default SlaDiff
