import React, { useState, useEffect, useContext } from "react"
import { radioOutline, navigateOutline, sendOutline, chevronDownOutline, cube, callOutline, musicalNotes } from "ionicons/icons"
import ReactMapGL, { Marker } from "react-map-gl"
import { ReactComponent as MarkerIcon } from "../assets/marker.svg"
import CommentCard from "./CommentCard"
import { IonList, IonText, IonButton, IonItem, IonLabel, IonIcon, IonCard, IonCardContent } from "@ionic/react"
import { DatabaseContext } from "../components/DatabaseContext"
import { useTranslation } from "react-i18next"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import PictureList from "./PictureList"
import ValueList from "./ValueList"
import MoreInfo from "./MoreInfo"
import convert from "../convert"
import useWindowSize from "../hooks/useWindowSize"

const PlaceGeneral = (props) => {
	const { t } = useTranslation("link_app")
	const [width] = useWindowSize()

	// Databases
	const databases = useContext(DatabaseContext)

	// State for comment inputs
	const [comment, setComment] = useState("")

	const MAPBOX_TOKEN = "pk.eyJ1Ijoib2xsaWthcmtrYWluZW4iLCJhIjoiY2tpN2NjMGxwMzNjZjMwbHR4Y2tjbWtrcyJ9.pXEKiASHCH_D4hzCaquTyw"

	// Map viewport
	const [viewport, setViewport] = useState({
		longitude: null,
		latitude: null,
		zoom: 8,
	})

	// Fetch all contacts
	const [contacts, setContacts] = useState([])
	useEffect(() => {
		databases.locals.contacts
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				setContacts(docs)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.contacts])

	useEffect(() => {
		if (props.doc.coordinates && props.doc.coordinates?.length > 0 && viewport.longitude === null && viewport.latitude === null) {
			const long = convert(props.doc.coordinates[0])
			const lat = convert(props.doc.coordinates[1])
			setViewport({
				...viewport,
				longitude: long,
				latitude: lat,
			})
		}
	}, [props.doc.coordinates, viewport])

	// Function that removes comment from ticket
	const handleCommentRemove = (commentId) => {
		const comments = [...props.doc.comments]
		const commentIndex = comments.findIndex((comment) => comment.id === commentId)
		comments.splice(commentIndex, 1)
		props.setDoc({ ...props.doc, comments: comments })
		props.setIsEdited(true)
	}

	// Function that handles comment upload to ticket
	const handleCommentUpload = () => {
		const comments = [...props.doc.comments]
		const timestamp = Math.round(new Date() / 1000).toString()
		let newId =
			comments.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								comments.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const newComment = { id: newId, time: timestamp, contact_id: props.user.name, text: comment !== "" ? comment : "" }
		props.setDoc({ ...props.doc, comments: [...comments, newComment] })
		props.setIsEdited(true)
	}

	return (
		<IonList>
			<IonItem lines="full">
				<div className="inner-wrap">
					<Accordion defaultExpanded>
						<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
							<IonText>
								<h5 className="ion-margin-none">{t("places.address")}</h5>
							</IonText>
						</AccordionSummary>
						<AccordionDetails>
							<IonCard className="ion-border">
								<IonItem lines="none" className="ion-margin-top-half ion-margin-bottom-half">
									<div className={props.selectionType === "site" ? "icon-wrap danger" : props.selectionType === "warehouse" ? "icon-wrap warning" : "icon-wrap primary"} slot="start">
										<IonIcon
											icon={props.selectionType === "site" ? radioOutline : props.selectionType === "warehouse" ? cube : musicalNotes}
											style={{
												fontSize: "24px",
											}}
											color={props.selectionType === "warehouse" ? "dark" : "light"}
										/>
									</div>
									<IonLabel>
										{props.doc.name}
										<br />
										<span style={{ opacity: "0.5" }}>
											{props.doc.address}
											{`, ${props.doc.postal_address}`}
										</span>
									</IonLabel>
								</IonItem>
								<IonCardContent className="ion-padding-none">
									{viewport.longitude && viewport.latitude && (
										<ReactMapGL className="place-card-map" {...viewport} width={`${width - 32}px`} height="250px" mapboxApiAccessToken={MAPBOX_TOKEN} mapStyle="mapbox://styles/mapbox/outdoors-v11" onViewportChange={setViewport}>
											<Marker longitude={convert(props.doc.coordinates[0])} latitude={convert(props.doc.coordinates[1])} offsetLeft={-24} offsetTop={-60}>
												<div className="marker-inner-wrap">
													<MarkerIcon className={props.selectionType === "site" ? "map-marker danger" : props.selectionType === "warehouse" ? "map-marker warning" : "map-marker primary"} />
													<div className="marker-status">
														<IonIcon className="marker-icon" color={props.selectionType === "warehouse" ? "dark" : "light"} icon={props.selectionType === "site" ? radioOutline : props.selectionType === "warehouse" ? cube : musicalNotes} />
													</div>
												</div>
											</Marker>
										</ReactMapGL>
									)}
									{props.doc?.contact_personel && props.doc?.contact_personel?.person_1?.name && (
										<IonItem href={`tel:${props.doc?.contact_personel?.person_1?.phone_number}`} lines="none" className="ion-padding-top-half ion-padding-bottom-half">
											<div className="icon-wrap primary" slot="start">
												{props.doc?.contact_personel?.person_1?.name !== "" && props.doc?.contact_personel?.person_1?.name.split(" ").length >= 1 ? (
													<>
														{props.doc?.contact_personel?.person_1?.name.split(" ").length >= 1 && props.doc?.contact_personel?.person_1?.name.split(" ")[0].charAt(0)}
														{props.doc?.contact_personel?.person_1?.name.split(" ").length > 1 && props.doc?.contact_personel?.person_1?.name.split(" ")[1].charAt(0)}
													</>
												) : (
													"?"
												)}
											</div>
											<IonLabel>
												{props.doc?.contact_personel?.person_1?.name}
												<br />
												<span className="contact-title">{props.doc?.contact_personel?.person_1?.phone_number}</span>
											</IonLabel>
											<IonIcon icon={callOutline} color="primary" slot="end" />
										</IonItem>
									)}
									{props.doc?.contact_personel && props.doc?.contact_personel?.person_2?.name && (
										<IonItem href={`tel:${props.doc?.contact_personel?.person_2?.phone_number}`} lines="none" className="ion-padding-top-half ion-padding-bottom-half">
											<div className="icon-wrap primary" slot="start">
												{props.doc?.contact_personel?.person_2?.name !== "" && props.doc?.contact_personel?.person_2?.name.split(" ").length >= 1 ? (
													<>
														{props.doc?.contact_personel?.person_2?.name.split(" ").length >= 1 && props.doc?.contact_personel?.person_2?.name.split(" ")[0].charAt(0)}
														{props.doc?.contact_personel?.person_2?.name.split(" ").length > 1 && props.doc?.contact_personel?.person_2?.name.split(" ")[1].charAt(0)}
													</>
												) : (
													"?"
												)}
											</div>
											<IonLabel>
												{props.doc?.contact_personel?.person_2?.name}
												<br />
												<span className="contact-title">{props.doc?.contact_personel?.person_2?.phone_number}</span>
											</IonLabel>
											<IonIcon icon={callOutline} color="primary" slot="end" />
										</IonItem>
									)}
									<IonButton className="ion-margin-left-half ion-margin-top-half ion-margin-bottom-half" href={`https://www.google.com/maps/search/?api=1&query=${convert(props.doc.coordinates[1])},${convert(props.doc.coordinates[0])}`} fill="clear" target="_blank">
										<IonIcon className="marker-icon" slot="start" icon={navigateOutline} color="primary" />
										{t("places.navigate")}
									</IonButton>
								</IonCardContent>
							</IonCard>
						</AccordionDetails>
					</Accordion>
				</div>
			</IonItem>
			{props.doc?.comments && (
				<IonItem lines="full">
					<div className="inner-wrap">
						<Accordion>
							<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
								<IonText>
									<h5 className="ion-margin-none">
										{t("places.comments")} ({props.doc?.comments?.length})
									</h5>
								</IonText>
							</AccordionSummary>
							<AccordionDetails>
								{props.doc?.comments?.map((comment, i) => {
									const contactIndex = contacts.findIndex((contact) => contact.doc._id === comment.contact_id)
									const contact = contacts[contactIndex]
									return <CommentCard key={i} contact={contact} comment={comment} handleCommentRemove={handleCommentRemove} />
								})}
								<div className="file-upload">
									<textarea placeholder="Kirjoita kommentti" value={comment} onChange={(e) => setComment(e.target.value)} autoComplete="off" />
									<IonButton
										className="file-upload-button"
										disabled={comment === ""}
										color="primary"
										size="default"
										fill="clear"
										onClick={() => {
											handleCommentUpload()
											setComment("")
										}}
									>
										<IonIcon slot="icon-only" icon={sendOutline} />
									</IonButton>
								</div>
							</AccordionDetails>
						</Accordion>
					</div>
				</IonItem>
			)}

			{Object.keys(props.doc.general).map((key, i) => {
				return (
					<IonItem lines="full" key={i}>
						<div className="inner-wrap">
							<Accordion>
								<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
									<IonText>
										<h5 className="ion-margin-none">{t(`places.${key}`)}</h5>
									</IonText>
								</AccordionSummary>
								<AccordionDetails>
									<PictureList place={props.doc} tabName="general" listName={key} />
									<ValueList place={props.doc} tabName="general" listName={key} />
									<MoreInfo place={props.doc} tabName="general" listName={key} />
								</AccordionDetails>
							</Accordion>
						</div>
					</IonItem>
				)
			})}
		</IonList>
	)
}

export default PlaceGeneral
