import React, { useRef, useEffect, useState, useContext } from "react"
import Header from "../components/Header"
import CommentCard from "../components/CommentCard"
import { UserContext } from "../components/UserContext"
import { Link } from "react-router-dom"
import { IonPage, IonContent, IonList, IonText, IonButton, IonItem, IonThumbnail, IonIcon, IonLabel, IonCheckbox, IonImg } from "@ionic/react"
import { trashOutline, sendOutline, imageOutline, checkmarkOutline, closeOutline, chevronDownOutline, syncOutline } from "ionicons/icons"
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core"
import { DatabaseContext } from "../components/DatabaseContext"
import Loading from "../components/Loading"
import useToast from "../hooks/useToast"
import { useTranslation } from "react-i18next"
import Compressor from "compressorjs"

const Documentation = (props) => {
	// Misc vars
	const match = props.match
	const fileInput = useRef(null)
	const { t } = useTranslation("link_app")
	const toast = useToast()

	// Databases
	const databases = useContext(DatabaseContext)

	// Get current user from user context
	const [user] = useContext(UserContext)

	// Edited state
	const [isEdited, setIsEdited] = useState(false)

	// Updated state
	const [isUpdated, setIsUpdated] = useState(false)

	// States for input fieds
	const [image, setImage] = useState({})
	const [caption, setCaption] = useState("")
	const [comment, setComment] = useState("")

	// Fetch the ticket
	const [doc, setDoc] = useState({})
	useEffect(() => {
		databases.locals.tickets
			.get(match.params.id, { attachments: true })
			.then((result) => {
				setDoc(result)
				setIsUpdated(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.tickets, match, props.isLoading, isUpdated])

	useEffect(() => {
		document.title = `${t("tickets.ticket")} #${doc._id} ${window.location.host.includes("localhost") || window.location.host.includes("staging") ? "[STAGING] " : ""}- Link App - Telemast`
	}, [doc, t, match])

	// Update changes to database
	useEffect(() => {
		if (isEdited) {
                        setIsEdited(false)
			databases.locals.tickets
				.put(doc)
				.then((result) => {
					console.log(`${t("toast.ticket")} #${doc._id} ${t("toast.updated")}`)
					toast(`${t("toast.ticket")} #${doc._id} ${t("toast.updated")}`)
					setIsUpdated(true)
				})
				.catch((err) => {
					console.log(`${t("toast.error_updating_ticket")} #${doc._id}:`, err)
					toast(`${t("toast.error_updating_ticket")} #${doc._id}`)
                                        setIsEdited(true)
				})
		}
	}, [isEdited, doc, databases.locals.tickets, match, t, toast])

	// Fetch all contacts
	const [contacts, setContacts] = useState([])
	useEffect(() => {
		databases.locals.contacts
			.allDocs({
				include_docs: true,
			})
			.then((result) => {
				const docs = result.rows.filter((row) => {
					return !row.id.includes("_design")
				})
				setContacts(docs)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [databases.locals.contacts])

	// Compress image before upload
	const handleCompressedUpload = (e) => {
		const img = e.target.files[0]
		new Compressor(img, {
			quality: 0.6, // 0.6 can be used, but it is not recommended to go below
			success: (result) => {
				// result has the compressed file
				setImage(result)
			},
		})
	}

	// Add image and caption to ticket
	const handleImageUpload = () => {
		if (doc.images.findIndex((x) => x.attachment_id === image.name) === -1) {
			const timestamp = Math.round(new Date() / 1000).toString()
			const attachments = {
				...doc._attachments,
				[image.name !== "image.jpg" ? image.name.replace(/ /g, "-") : `${timestamp}.jpg`]: {
					content_type: image.type,
					data: image,
				},
			}
			const images = [...doc.images]
			let newId =
				images.length > 0
					? (
							parseInt(
								Math.max.apply(
									Math,
									images.map((x) => x.id)
								)
							) + 1
					  ).toString()
					: "1"
			const newImages = [...images, { id: newId, attachment_id: image.name !== "image.jpg" ? image.name.replace(/ /g, "-") : `${timestamp}.jpg`, text: caption !== "" ? caption : "" }]

			let newLogId =
				doc.log?.length > 0
					? (
							parseInt(
								Math.max.apply(
									Math,
									doc.log?.map((x) => x.id)
								)
							) + 1
					  ).toString()
					: "1"
			const ticket = {
				...doc,
				_attachments: attachments,
				images: newImages,
				log: doc.log ? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: "image_loaded" }] : [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: "image_loaded" }],
			}

			setDoc(ticket)
			setIsEdited(true)
			setImage({})
			setCaption("")
		} else {
			toast(`${t("toast.image")} "${image.name}" ${t("toast.is_already_uploaded")}`)
		}
	}

	// Remove image and caption from ticket
	const handleImageRemove = (imageId) => {
		const attachments = { ...doc._attachments }
		delete attachments[imageId]
		const images = [...doc.images]
		const imageIndex = images.findIndex((image) => image.attachment_id === imageId)
		images.splice(imageIndex, 1)

		const timestamp = Math.round(new Date() / 1000).toString()
		let newLogId =
			doc.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								doc.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticket = {
			...doc,
			_attachments: attachments,
			images: images,
			log: doc.log ? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: "image_deleted" }] : [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: "image_deleted" }],
		}

		setDoc(ticket)
		setIsEdited(true)
	}

	// Remove comment from ticket
	const handleCommentRemove = (commentId) => {
		const comments = [...doc.comments]
		const commentIndex = comments.findIndex((comment) => comment.id === commentId)
		comments.splice(commentIndex, 1)

		const timestamp = Math.round(new Date() / 1000).toString()
		let newLogId =
			doc.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								doc.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticket = {
			...doc,
			comments: comments,
			log: doc.log ? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: "comment_deleted" }] : [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: "comment_deleted" }],
		}
		setDoc(ticket)
		setIsEdited(true)
	}

	// Function that handles comment upload to ticket
	const handleCommentUpload = () => {
		const comments = [...doc.comments]
		const timestamp = Math.round(new Date() / 1000).toString()
		let newId =
			comments.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								comments.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const newComment = { id: newId, time: timestamp, contact_id: user.name, text: comment !== "" ? comment : "" }

		let newLogId =
			doc.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								doc.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticket = {
			...doc,
			comments: [...comments, newComment],
			log: doc.log ? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: "commented" }] : [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: "commented" }],
		}
		setDoc(ticket)
		setIsEdited(true)
	}

	// Add documentation completed timestamp to ticket
	const handleDocumentationComplete = () => {
		const timestamp = Math.round(new Date() / 1000).toString()
		let newLogId =
			doc.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								doc.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticket = {
			...doc,
			time_when_documented: doc.time_when_documented === "" ? timestamp : "",
			log: doc.log
				? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: doc.time_when_documented === "" ? "documented" : "cancel_documented" }]
				: [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: doc.time_when_documented === "" ? "documented" : "cancel_documented" }],
		}
		setDoc(ticket)
		setIsEdited(true)
	}

	// Handle accessory checkbox click
	const handleCheck = (accessoryId) => {
		const accessories = [...doc.accessories]
		const index = accessories.findIndex((accessory) => accessory.id === accessoryId)
		accessories[index].is_used = !accessories[index].is_used

		const timestamp = Math.round(new Date() / 1000).toString()
		let newLogId =
			doc.log?.length > 0
				? (
						parseInt(
							Math.max.apply(
								Math,
								doc.log?.map((x) => x.id)
							)
						) + 1
				  ).toString()
				: "1"
		const ticket = {
			...doc,
			accessories: accessories,
			log: doc.log
				? [...doc.log, { id: newLogId, time: timestamp, user: user, app: "mobile", event: `accessory_${accessories[index].name}: ${accessories[index].is_used}` }]
				: [{ id: newLogId, time: timestamp, user: user, app: "mobile", event: `accessory_${accessories[index].name}: ${accessories[index].is_used}` }],
		}

		setDoc(ticket)
		setIsEdited(true)
	}

	return (
		<IonPage>
			<Header title={t("tickets.documentation")} backButton />
			<IonContent>
				{doc._id ? (
					<>
						<IonList>
							<IonItem lines="full">
								<div className="inner-wrap">
									<Accordion defaultExpanded>
										<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
											<IonText>
												<h4 className="ion-margin-none">
													{t("tickets.images")} ({doc._attachments ? Object.keys(doc._attachments).length : "0"})
												</h4>
											</IonText>
										</AccordionSummary>
										<AccordionDetails>
											{doc.documentation_guide !== "" && (
												<IonText>
													<p>{doc.documentation_guide}</p>
												</IonText>
											)}
											{doc.images.length > 0 &&
												doc.images.map((image, i) => {
													let attachment
													if (doc._attachments && image.attachment_id !== "") {
														attachment = doc._attachments[image.attachment_id]
													}
													return (
														<IonItem key={i} lines="none" className="ion-margin-bottom">
															<Link className="attachment-link" to={`/tickets/${doc._id}/attachments/${image.attachment_id}`}>
																<IonThumbnail className="attachment-thumbnail" slot="start">
																	{attachment && attachment.digest && <IonImg src={`data:${attachment.content_type};base64,${attachment.data}`} alt="" />}
																	{attachment && !attachment.digest && <IonImg src={window.URL.createObjectURL(attachment.data)} alt="" />}
																</IonThumbnail>
																<IonText className="ion-margin-left">
																	<p className="ion-margin-none">{image.text}</p>
																</IonText>
															</Link>
															<IonButton className="no-margin" slot="end" onClick={() => handleImageRemove(image.attachment_id)} size="small" fill="clear" color="medium">
																<IonIcon slot="icon-only" icon={trashOutline} />
															</IonButton>
														</IonItem>
													)
												})}
											<div className="file-upload">
												<div className="file-input-container">
													<div className="file-input-wrap">
														{image.name && <p className="ion-margin-none ion-margin-right-half">{image.name}</p>}
														<IonButton size="default" fill="clear" color={image.name ? "medium" : "primary"} onClick={() => fileInput.current.click()}>
															<IonIcon className="ion-margin-right-half" slot="start" icon={image.name ? syncOutline : imageOutline} />
															{image.name ? t("tickets.change_image") : t("tickets.select_image")}
														</IonButton>
														<input ref={fileInput} type="file" accept="image/*" hidden value="" onChange={(e) => handleCompressedUpload(e)} />
													</div>
													{image.name && <textarea placeholder="Kirjoita kuvateksti" value={caption} onChange={(e) => setCaption(e.target.value)} autoComplete="off" />}
												</div>
												{image.name && (
													<IonButton className="image-upload-button" size="default" fill="clear" onClick={() => handleImageUpload()}>
														<IonIcon slot="icon-only" icon={sendOutline} />
													</IonButton>
												)}
											</div>
										</AccordionDetails>
									</Accordion>
								</div>
							</IonItem>
							<IonItem lines="full">
								<div className="ticket-inner-wrap">
									<Accordion defaultExpanded>
										<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
											<IonText>
												<h4 className="ion-margin-none">
													{t("tickets.comments")} ({doc.comments.length})
												</h4>
											</IonText>
										</AccordionSummary>
										<AccordionDetails>
											{doc.comments.map((comment, i) => {
												const contactIndex = contacts.findIndex((contact) => contact.doc._id === comment.contact_id)
												const contact = contacts[contactIndex]
												return <CommentCard key={i} contact={contact} comment={comment} handleCommentRemove={handleCommentRemove} />
											})}
											<div className="file-upload">
												<textarea placeholder={t("app.write_comment")} value={comment} onChange={(e) => setComment(e.target.value)} autoComplete="off" />
												<IonButton
													className="file-upload-button"
													disabled={comment === ""}
													color="primary"
													size="default"
													fill="clear"
													onClick={() => {
														handleCommentUpload()
														setComment("")
													}}
												>
													<IonIcon slot="icon-only" icon={sendOutline} />
												</IonButton>
											</div>
										</AccordionDetails>
									</Accordion>
								</div>
							</IonItem>
							<IonItem lines="full">
								<div className="inner-wrap">
									<Accordion defaultExpanded>
										<AccordionSummary expandIcon={<IonIcon icon={chevronDownOutline} color="primary" />}>
											<IonText>
												<h4 className="ion-margin-none">
													{t("tickets.used_accessories")} ({doc.accessories.filter((accessory) => accessory.is_used).length})
												</h4>
											</IonText>
										</AccordionSummary>
										<AccordionDetails>
											{doc.accessories.map((item, i) => {
												return (
													<IonItem key={i} lines="none" onClick={() => handleCheck(item.id)}>
														<IonLabel>{item.name}</IonLabel>
														<IonCheckbox slot="start" value={item.name} checked={item.is_used} />
													</IonItem>
												)
											})}
										</AccordionDetails>
									</Accordion>
								</div>
							</IonItem>
						</IonList>
						<div className="ion-padding">
							{doc.time_when_documented === "" ? (
								<IonButton color="success" expand="block" onClick={() => handleDocumentationComplete()}>
									<IonIcon slot="start" icon={checkmarkOutline} />
									{t("tickets.mark_ready")}
								</IonButton>
							) : (
								<IonButton color="danger" expand="block" onClick={() => handleDocumentationComplete()}>
									<IonIcon slot="start" icon={closeOutline} />
									{t("tickets.cancel")}
								</IonButton>
							)}
						</div>
					</>
				) : (
					<Loading />
				)}
			</IonContent>
		</IonPage>
	)
}

export default Documentation
