import React, { useEffect, useState } from "react"
import { IonText, IonList, IonItem, IonThumbnail, IonImg, IonRow, IonCol, IonGrid } from "@ionic/react"
import { Link } from "react-router-dom"
import _ from "lodash"

const PictureList = (props) => {
	// Get tab & list names
	const [tabName, setTabName] = useState("")
	const [listName, setListName] = useState("")
	useEffect(() => {
		if (props.tabName && props.listName) {
			setTabName(props.tabName)
			setListName(props.listName)
		}
	}, [props])

	//  Get images & attachments
	let images = []
	if (props.place[tabName] && props.place[tabName][listName]) {
		images = props.place[tabName][listName].images
	}
	let attachments = []
	if (!_.isEmpty(props.place._attachments)) {
		attachments = props.place._attachments
	}

	return (
		<IonGrid className="ion-padding-none ion-margin-top">
			<IonRow className="ion-margin-none">
				<IonCol className="ion-margin-none">
					<IonList>
						{images.map((image, i) => {
							let attachment
							if (attachments && image.attachment_id !== "") {
								attachment = attachments[image.attachment_id]
							}
							return (
								<IonItem key={i} lines="none" className="ion-margin-bottom">
									<Link className="attachment-link" to={props.fmServiceId ? `/places/fm-service/${props.fmServiceId}/attachments/${image.attachment_id}` : `/places/${props.place.type}/${props.place._id}/attachments/${image.attachment_id}`}>
										<IonThumbnail slot="start">
											{attachment && attachment.digest && <IonImg src={`data:${attachment.content_type};base64,${attachment.data}`} alt="" />}
											{attachment && !attachment.digest && <IonImg src={window.URL.createObjectURL(attachment.data)} alt="" />}
										</IonThumbnail>
										<IonText className="ion-margin-left">{image.text}</IonText>
									</Link>
								</IonItem>
							)
						})}
					</IonList>
				</IonCol>
			</IonRow>
		</IonGrid>
	)
}
export default PictureList
