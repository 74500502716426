import React from "react"

const DateTime = (props) => {
	const date = new Date(props.timestamp * 1000)
	const year = date.getFullYear()
	const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
	const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
	const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
	const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
	const dateString = `${day}.${month}.${year}, ${hour}.${minute}`

	return <>{dateString}</>
}

export default DateTime
